.generic-copy {
	position:relative;
	padding:100px 0px;

	.copy-image + & {
		padding-top:0px;
	}

	&__copy {
		@extend %typography--muted;
	}

	@include media-breakpoint-down(sm) {
		padding:40px 0px;
	}
}
