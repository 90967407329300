@font-face {
    font-family: 'Monorale';
    src: url('../fonts/Monorale-Light.otf'),
        url('../fonts/Monorale-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Monorale';
    src: url('../fonts/Monorale-LightItalic.otf'),
        url('../fonts/Monorale-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Monorale';
    src: url('../fonts/Monorale-Regular.otf'),
        url('../fonts/Monorale-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Monorale';
    src: url('../fonts/Monorale-Italic.otf'),
        url('../fonts/Monorale-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Monorale';
    src: url('../fonts/Monorale-Medium.otf'),
        url('../fonts/Monorale-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Monorale';
    src: url('../fonts/Monorale-MediumItalic.otf'),
        url('../fonts/Monorale-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
  }