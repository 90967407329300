.cookie-notice {
	position:fixed;
	z-index:300;
	left:0px;
	bottom:0px;
	padding:24px;
	width:100%;

	&__inner {
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:space-around;
		max-width:603px;
		margin:0 auto;
		padding:16px;
		background:$darkgrey;
		text-align:center;
		color:white;

		p {
			margin:8px 0px;

			img,
			a {
				margin:0px 6px;
			}

			a {
				white-space:nowrap;
				text-decoration:underline;
				color:inherit;
			}
		}
	}
}