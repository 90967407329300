.accordion-tabs {
	position:relative;
	padding:64px 0px 32px;

	@include media-breakpoint-up(md) {
		padding:104px 0px 112px;
	}

	&__title {
		max-width:811px;
		margin:0px 0px 30px;
		color:$dark;

		@include media-breakpoint-up(md) {
			margin-bottom:144px;
		}
	}

	&__panel {
		border-top:1px solid #BEBEBE;
		padding:33px 0px 32px;

		&__toggle {
			display:block;
			margin:0px;
			padding:0px;
			border:none;
			border-radius:0px;
			background:transparent;
			font-size:fontsize(20);
			font-weight:$font-weight-bold;
			transition:opacity 400ms ease;

			&.collapsed {
				opacity:0.3;
			}
		}

		&__body {
			padding:16px 0px 0px;
			color:#767676;
		}
	}

	&__image {
		&--desktop {
			margin:0px 0px -112px;

			img {
				position:absolute;
				right:0px;
				bottom:0px;
				width:auto;
				height:auto;
				max-width:100%;
				opacity:0;
				transition:opacity 300ms ease;

				&.active {
					opacity:1;
				}
			}

			@include media-breakpoint-down(sm) {
				display:none;
			}
		}

		&--mobile {
			margin:39px auto 0px;
			padding:0px 30px;
			text-align:center;

			img {
				width:auto;
				height:auto;
				max-width:100%;
			}

			@include media-breakpoint-up(md) {
				display:none;
			}
		}
	}
}