.copy-features {
	position:relative;
	padding:40px 0px 0px;
	background:$dark url('../img/copy-features/bg.png') no-repeat center/cover;
	color:white;

	@include media-breakpoint-up(lg) {
		padding:40px 0px;
	}

	&__inner {
		@include make-row();
	}

	&__copy,
	&__features {
		@include make-col-ready();
		@include make-col(12);

		@include media-breakpoint-up(lg) {
			@include make-col(6);
		}
	}

	&__copy {
		align-self:center;

		&__buttons {
			margin:35px 0px 0px;
		}

		@include media-breakpoint-up(xl) {
			padding-right:100px;
		}
	}

	&__features {
		@include media-breakpoint-down(md) {
			margin:40px 0px 0px;
		}
	}

	&__feature {
		@include clearfix();
		padding:20px 0px 25px 125px;

		& + & {
			border-top:1px solid rgba(#BEBEBE, 0.5);
		}

		&__image {
			float:left;
			width:83px;
			height:83px;
			margin:0px 0px 0px -115px;
			object-fit:contain;
		}

		&__title {
			margin-bottom:2px;
		}

		&__body {
			max-width:328px;
			color:#ECEEF0;
		}

		@include media-breakpoint-up(sm) {
			padding:40px 0px 25px 153px;

			&__image {
				margin:0px 0px 0px -143px;
			}
		}
	}
}