.stacking-tiles {
	position:relative;
	padding:43px 0px;

	@include media-breakpoint-up(md) {
		padding:88px 0px;
	}

	&__title {
		margin:0px 0px 32px;
	}

	.row {
		margin-bottom:$grid-gutter-width*-0.5;

		> .col-12 {
			padding-bottom:$grid-gutter-width*0.5;
		}
	}

	&__tile {
		height:100%;
		padding:20px 32px 32px;
		border:1px solid #BEBEBE;
		border-radius:16px;
		background:white;

		&__lead {
			font-size:fontsize(12);
			color:#BEBEBE;
		}

		&__title {
			margin:0px 0px 50px;
			color:$primary;
		}

		&__body {
			@extend %typography--muted;
		}
	}
}