.separator {
	position:relative;

	&--line {
		@include media-breakpoint-up(md) {
			.blog-posts + & {
				margin-top:-60px;
			}
		}

		@include media-breakpoint-down(sm) {
			.blog-posts + & {
				display:none;
			}
		}
	}

	&--glow {
		img {
			position:absolute;
			top:0px;
			right:0px;
			z-index:-1;
			width:422px;
			height:729px;
			transform:translate(0, -50%);
		}
	}
}