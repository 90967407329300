.container-image {
	position:relative;
	padding:34px 0px;

	.profiles + & {
		margin-top:-15px;
		padding-top:0px;
	}

	&__image {
		&--desktop,
		&--mobile {
			width:100%;
			height:auto;
		}

		@include media-breakpoint-up(md) {
			&--mobile {
				display:none;
			}
		}

		@include media-breakpoint-down(sm) {
			&--desktop {
				display:none;
			}
		}
	}
}