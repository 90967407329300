%typography--muted {
	color:$text-muted;

	h1,h2,h3,h4,h5,h6 {
		color:$dark;
	}
}

.typography {
	h1,h2 {
		margin:30px 0px;
	}

	h3 {
		margin:25px 0px;
	}

	h4,h5 {
		margin:20px 0px;
	}

	h6 {
		margin:10px 0px;
	}

	p,ul,ol {
		margin:8px 0px;
	}

	ul,ol {
		li {
			+ li {
				margin-top:8px;
			}
		}
	}

	img,
	figure {
		margin:60px 0px;
	}

	figure {
		img {
			margin-top:0px;
			margin-bottom:0px;
		}
	}

	table {
		th {
			padding:18px 30px;
			font-size:fontsize(20);
			color:$body-color;
		}

		td {
			padding:13px 30px 0px;
		}

		th:first-child,
		td:first-child {
			padding-left:0px;
		}

		th:last-child,
		td:last-child {
			padding-right:0px;
		}

		thead {
			th,
			td {
				white-space:nowrap;
				border-bottom:1px solid $text-muted;
			}
		}
	}

	.table-wrap {
		overflow:auto;
	}

	&__read-more {
		margin:8px 0px;

		> button {
			display:block;
			margin:0px;
			padding:0px;
			border:none;
			border-radius:0px;
			background:transparent;
			font:inherit;
			color:$link-color;
		}

		&__inner {
			padding-top:8px;
		}
	}

	> :first-child,
	&__read-more__inner > :first-child {
		margin-top:0px;
	}

	> :last-child,
	&__read-more__inner > :last-child {
		margin-bottom:0px;
	}
}