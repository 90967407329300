.btn {
    transition: all 0.2s ease-in-out 0s;
    font-family: 'Monorale', sans-serif;
    border-radius: .25rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
    background: transparent;
    font-size: .85rem;
    padding: .64rem .75rem;
    max-height: 2.4rem;
    line-height: 1rem;
    text-transform: none;
    i {
        margin-right: 0.4rem;
        font-size: 0.75rem;
    }
    &:not(.btn-primary):not(.tooltip-trigger):not(.inline-header-filter-trigger) {
        min-width: 1rem !important;
    }
  }

 .btn-primary {
      background-color: #a1d019;
      border-color: #8fba16;
      color: #191919;
      -webkit-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
      -moz-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
      box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;

      &:hover,&:not(:disabled):not(.disabled):active{
        background-color: #8fba16;
        border-color: #8fba16;
        color: #191919;
      }
    }

    .btn-secondary {
        &:hover , &:not(:disabled):not(.disabled):active {
            background-color: #eaeaea;
            border-color: #d8d8d8;
            color: #191919;
        }
    }

  .btn-secondary, .Select-clear, .Select-value-icon {
      background-color: #f5f5f5;
      border-color: #ddd;
      color: #191919;
      -webkit-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
      -moz-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
      box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
    }

    @media (min-width: 768px) {
        .btn-primary {
            min-width: 12rem; 
        }
        .btn:not(.btn-primary):not(.tooltip-trigger):not(.inline-header-filter-trigger) {
            min-width: 1rem;        
        }
        .btn {
            &.smaller {
                max-height: 30px;
                padding: 0.38rem 0.75rem  
            }
            &.icon-only {
                min-width: 2.7rem;
                min-width: 1rem;
                &.smaller {
                    width: 30px;
                    padding: 0.38rem 0;
                    min-width: 0;
                }
            }
            &.show-on-small {
                display: none;
            }
            &.hide-on-small {
                display: inline-block;
            }
        }
    }