.asx-block {
	position:relative;

	.copy-image + & {
		padding-top:0px;
	}

	@include media-breakpoint-up(sm) {
		padding:85px 0px;
	}

	@include media-breakpoint-down(xs) {
		.container {
			padding:0px;
		}
	}

	&__inner {
		padding:0px $grid-gutter-width/2;
		background:$light;

		@include media-breakpoint-up(sm) {
			padding:0px 80px;
			border-radius:16px;
		}
	}

	&__events {
		padding:39px 0px 49px;

		@include media-breakpoint-up(sm) {
			padding:52px 0px 43px;
		}

		p {
			a {
				font-weight:$font-weight-light;
				text-decoration:underline;
				color:inherit;
			}
		}

		&__title {
			margin:0px 0px 12px;
		}

		&__link--major + &__title,
		&__link--minor + &__title {
			margin-top:32px;
		}

		&__link--major,
		&__link--minor {
			display:flex;
			margin:9px 0px;
			padding:25.5px 16px;
			border-radius:8px;
			background:white;
			font-size:fontsize(16);
			font-weight:$font-weight-bold;
			color:inherit;
			transition:background 300ms ease;

			@at-root a#{&} { // They're <span>'s not <a>'s in phase 1
				@include hover-focus {
					background:#d6d9e1;
					text-decoration:none;
					color:inherit;
				}
			}

			@include media-breakpoint-up(sm) {
				padding:22.5px 24px;
				font-size:fontsize(20);
			}
		}

		&__link--major {
			:first-child {
				min-width:86px;
				max-width:86px;

				@include media-breakpoint-up(sm) {
					min-width:109px;
					max-width:109px;
				}
			}

			:last-child {
				min-width:16px;
				max-width:16px;
				margin-left:auto;
			}
		}

		&__link--minor {
			:last-child {
				align-self:center;
				margin-left:auto;
				font-size:fontsize(12);
				font-weight:$font-weight-light;
			}
		}
	}

	&__events + &__status {
		@include media-breakpoint-up(sm) {
			border-top:1px solid #BEBEBE;
		}

		@include media-breakpoint-down(xs) {
			padding-top:0px;
		}
	}

	&__status {
		padding:49px 0px 24px;

		@include media-breakpoint-up(sm) {
			padding:45px 0px 84px;
		}

		h3 {
			margin:0px 0px 17px;

			@include media-breakpoint-up(sm) {
				margin-bottom:48px;
			}
		}

		.row {
			align-items:stretch;
			margin:0px -6px -12px;

			> .col-12 {
				padding:0px 6px 12px;
			}
		}

		&__block {
			display:block;
			padding:32px 21px 40px;
			border-radius:16px;
			background:white;

			@include media-breakpoint-up(sm) {
				padding-left:41px;
				padding-right:41px;
			}

			& + & {
				margin-top:12px;
			}

			&__title {
				margin:0px 0px 23px;
			}
		}
	}
}