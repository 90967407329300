.hero--blog-post {
	background-color: #F4F5F7;
	overflow: visible;

	.container {
		text-align: center;
		max-width: 1000px;
		overflow: visible;
		margin-bottom: 100px;

		.typography {
			&__title {
				color: #4180F6;
				font-size: $h2-font-size;
			}

			&__subheading {
				color: #D0D1D3;
				font-size: $h4-font-size;
			}
		}
	}
}

#blog-post {
	display:flex;
	flex-direction:column;
	position:relative;
	padding:14px 14px 25px;
	margin: 80px 0;

	> .media {
		width: 770px;
		height: 500px;
		background-color: #ddd;
		margin: 80px auto;
		margin-top: -300px;
	}

	.typography {
		img {
			width: 100%;
		}

		iframe {
			width: 100% !important;
			height: 400px !important;
		}
	}

	.footer-links {
		margin: 80px auto 0;
		display: flex;
		justify-content: center;

		.btn {
		}
	}

	@include media-breakpoint-up(md) {
		> .container {
			max-width:650px + $grid-gutter-width;
			.typography {
				color: #747474;
			}
		}
	}
}
