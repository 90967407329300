.title-with-backlink {
  padding-bottom: 1rem;
  @include media-breakpoint-up(md) { 
    padding-top: 1.2rem;
    padding-bottom: 2rem;
  }
    .back-wrapper {
      padding: 1rem 0 0;

      @include media-breakpoint-up(md) { 
        padding: 0;
      }
    }

    .fa-angle-left {
    
    &:before {
        content: "\f104";
    }
    }

    h1 {
      @include media-breakpoint-up(md) { 
        max-width: 50%;
        line-height: 2.2rem;
        padding: 1rem 0 0;
        font-size: 1.8rem;
      }
    }

}