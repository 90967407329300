/**
 * BLOG SECTION
 */
$color-border: rgb(222, 226, 230);

.c-blog {
	//
	// LAYOUT
	//
	&__cards {
		
		@include media-breakpoint-up(lg) {
			padding-right: 2rem;
			border-right: 1px solid $color-border;
		}
		
		.row.align-items-stretch .col-12 {
			margin-bottom: 2rem;
		}
	}
	
	&__aside {
		
		@include media-breakpoint-up(lg) {
			padding-left: 2rem;
		}
		
		li {
			margin-bottom: .5rem !important;
			
			a:not(.c-blog__btn-category) {
				color: #999;
			}
		}
		
		&-group {
			.c-blog__heading {
				@extend h4;
				color: $color-orange;
				text-transform: uppercase;
			}
			
			&:not(:last-of-type) {
				padding-bottom: 2rem;
				margin-bottom: 2rem;
				border-bottom: 1px solid $color-border;
			}
			
			&:first-of-type {
				@include media-breakpoint-down(md) {
					padding-top: 2rem;
					margin-top: 2rem;
					border-top: 1px solid $color-border;
				}
			}
		}
	}
	
	&__intro {
		.slick-prev,
		.slick-next {
			position: absolute;
			display: block;
			width: 30px;
			height: 30px;
			top: 50%;
			text-indent: -99999em;
			overflow: hidden;
			padding: 0;
			margin: 0;
			border: none;
			background: transparent;
			line-height: normal;
			-webkit-font-smoothing: inherit;
			-moz-osx-font-smoothing: inherit;
			-webkit-appearance: none;
			z-index: 10;
			
			&:before {
				content: "";
				position: absolute;
				display: block;
				width: 100%;
				height: 4px;
				top: 0;
				left: 0;
				right: 0;
				background-color: white;
			}
		}
		
		.slick-prev {
			left: 0;
			transform: translate(0, -50%) rotate(-45deg);
			
			@include media-breakpoint-up(md) {
				left: 2em;
			}
			
			&:after {
				content: "";
				position: absolute;
				display: block;
				height: 100%;
				width: 4px;
				top: 0;
				left: 0;
				bottom: 0;
				background-color: white;
			}
		}
		
		.slick-next {
			right: 0;
			transform: translate(0, -50%) rotate(45deg);
			
			@include media-breakpoint-up(md) {
				right: 2em;
			}
			
			&:after {
				content: "";
				position: absolute;
				display: block;
				height: 100%;
				width: 4px;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: white;
			}
		}
	}
	
	&__post {
		@include media-breakpoint-up(lg) {
			padding-right: 2rem;
			border-right: 1px solid $color-border;
		}
		
		blockquote {
			position: relative;
			display: block;
			@extend h5;
			padding: 1em;
			margin: 2em;
			border-left: 10px solid $color-orange;
		}
	}
	
	//
	// TYPOGRAPHY
	//
	&__heading {
		@extend h3;
		color: $color-orange;
		text-transform: uppercase;
	}
	&__post-heading {
		@extend h2;
		color: $color-orange;
		text-transform: uppercase;
	}
	
	&__date {
		color: #999;
		font-weight: 700;
		font-size: 80%;
		
		.c-blog__heading + & {
			margin-top: -1em;
		}
	}
	
	&__btn-category {
		position: relative;
		display: inline-block;
		padding: .5em .75em;
		line-height: 1em;
		text-decoration: none;
		text-transform: uppercase;
		color: white;
		background-color: $color-orange;
		font-size: 75%;
			
		&:hover {
			text-decoration: none;
			color: #fff;
			background-color: lighten($color-orange, 10%);
		}
	}
	
	&__prev-next {
		position: relative;
		display: block;
		text-align: center;
	}
	
	&__prev {
		@include media-breakpoint-up(md) {
			float: left;
		}
		
		@include media-breakpoint-down(md) {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	&__next {
		@include media-breakpoint-up(md) {
			float: right;
		}
		
		@include media-breakpoint-down(md) {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	//
	// CARDS
	//
	.card {
		height: 100%;
	}
	&__card {
		position: relative;
		font-size: 75%;
		height: 100%;
		
		@include media-breakpoint-up(lg) {
			transform: translate(0,0);
			box-shadow: 0px 0px 0 rgba(black, .25);
			transition: transform .2s, box-shadow .2s;
			
			&:hover {
				transform: translate(-3px, -3px);
				box-shadow: 3px 3px 6px rgba(black, .2);
			}
		}
		
		.c-blog__date {
			margin-bottom: 0 !important;
		}
		
		.card-title {
			@extend h5;
			text-transform: uppercase;
		}
		
		.card-text {
			margin-bottom: 0 !important;
		}
		
		&-link {
			position: absolute;
			display: block;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			overflow: hidden;
			text-indent: -99999em;
			
			&:before {
				content: "";
				position: absolute;
				display: block;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}
	}
	
	
	//
	// CALL TO ACTION
	//
	&__filters {
		padding-top: 20px !important;
		padding-bottom: 20px !important;
		
		&-flex {
			position: relative;
			
			@include media-breakpoint-up(md) {
				display: flex;
				align-content: space-between;
				align-items: center;
			}
			@include media-breakpoint-down(md) {
				display: block;
			}
			
			h1, h2 {
				font-size:fontsize(16);
				margin: 0 25px;
				font-weight: 100;
				color: $color-orange;
				
				a {
					color: $color-orange !important;
					text-decoration: none !important;
				}
		
				.icon-wrap {
					display:inline-block;
					margin:-3px 16px 0px 0px;
					
					position: relative;
					&:before {
						content: "";
						position: absolute;
						display: block;
						width: 40px;
						height: 40px;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						background: $color-orange;
						border-radius: 100%;
						z-index: -1;
					}
				}
		
				@include media-breakpoint-down(md) {
					padding:0px 25px;
					font-size:fontsize(16);
				}
		
				@include media-breakpoint-down(sm) {
					padding:0px 5px;
				}
			}
		}
		
		&-description {
			text-align: right;
			font-size: 175%;
			color: $color-blue-dark;
			
			p {
				line-height: 1.2em;
				margin-bottom: .5em;
			}
			
			@include media-breakpoint-up(md) {
				flex: 1 1 auto;
				padding-right: 1em;
				
				p {
					margin-bottom: 0;
				}
			}
		}
		
		&-action {
			text-align: center;
			
			@include media-breakpoint-up(md) {
				flex: 0 1 auto;
				text-align: right;
			}
		}
	}
}