.hero {
	position:relative;
	padding:148px 0px 80px;

	&--theme-dark {
		color:white;
	}

	.inpage-menu + & {
		padding-top:187px;
	}

	@include media-breakpoint-up(md) {
		padding:105px 0px 150px;

		&--product {
			padding-top:198px;
			padding-bottom:134px;
		}

		.inpage-menu + & {
			padding-top:200px;
		}
	}

	&__bg {
		&--desktop,
		&--mobile {
			position:absolute;
			top:0px;
			left:0px;
			z-index:-1;
			width:100%;
			height:auto;
			min-height:100%;
			object-fit: cover;

			.hero--module.hero--theme-dark & {
				height:100%;
			}
		}

		&--desktop {
			.hero--product & {
				height:100%;
			}

			@include media-breakpoint-down(sm) {
				display:none;
			}
		}

		&--mobile {
			@include media-breakpoint-up(md) {
				display:none;
			}
		}
	}

	&__animation {
		position:absolute;
		top:0px;
		left:0px;
		width:100%;
		height:100%;
	}

	&__overlay {
		position:relative;

		h1,
		h2,
		h3 {
			max-width:758px;
			margin-left:auto;
			margin-right:auto;
		}

		p {
			max-width:604px;
			margin-left:auto;
			margin-right:auto;

			&.wider {
				max-width:624px;
			}
		}

		@include media-breakpoint-up(md) {
			font-size:fontsize(20);

			.hero--module & {
				text-align:center;
			}
		}
	}

	&__copy {
		.hero--module.hero--theme-light & {
			@extend %typography--muted;

			body.type-homePage & {
				color:$dark;
			}
		}

		.hero--product & {
			max-width:600px;
		}
	}

	&__buttons {
		margin:17px 0px 0px;

		@include media-breakpoint-up(md) {
			margin:80px 0px 0px;
		}
	}

	&__button {
		&--first {
			@extend .btn-primary;
			margin:15px 5px 0px 0px;

			.hero--product.hero--theme-light & {
				@extend .btn-dark;
			}

			.hero--product.hero--theme-dark & {
				@extend .btn-light;
			}
		}

		&--not-first {
			@extend .btn-secondary;
			margin:15px 5px 0px 0px;

			.hero--theme-dark & {
				@extend .btn-light;
			}
		}
	}
	
	//
	// 2022 updated
	//
	&--2022 {
		overflow: hidden;
		position: relative;
		
		.hero__bg--desktop {
			z-index: -2;
		}
		
		&:before {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(
				90deg,
				rgba($color-blue, 0) 0%, 
				rgba($color-purple, .7) 63%,
				rgba(#4F5FAB, .98) 83%,
				rgba(#446FB6, 1) 100%
			);
			z-index: -1;
		}
		
		&:after {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: url('/assets/img/logomark.svg') right center no-repeat;
			background-size: contain;
			transform: scale(.95);
			z-index: -1;
		}
		
		& .hero__copy {
			text-align: center;
			text-transform: uppercase;
			text-shadow: 6px 6px 6px $color-charcoal;
			
			h1 {
				display: inline-block;
				margin-left: auto;
				margin-right: auto;
			}
			
			.btn {
				text-shadow: none;
			}
			
			.icon {
				position: relative;
				
				&:before {
					content: "";
					position: absolute;
					display: block;
					width: 1.25em;
					height: 1.25em;
					top: 50%;
					left: 0;
					transform: translate(-1.75em, -50%);
					background: white;
					border-radius: 100%;
					box-shadow: 6px 6px 6px $color-charcoal;
				}
				
				&:after {
					content: "";
					position: absolute;
					display: block;
					width: 1.25em;
					height: 1.25em;
					top: 50%;
					left: 0;
					transform: translate(-1.75em, -50%);
					background: url('/assets/img/icons/icon-default.svg') center center no-repeat;
					background-size: .8em;
				}
				
				&.workforce-management {
					&:after {
						background: url('/assets/img/icons/icon-workforce-management-blue.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				&.elearning {
					&:after {
						background: url('/assets/img/icons/icon-elearning-blue.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				&.access-control {
					&:after {
						background: url('/assets/img/icons/icon-access-control-blue.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				&.solo {
					&:after {
						background: url('/assets/img/icons/icon-solo-blue.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				&.safety {
					&:after {
						background: url('/assets/img/icons/icon-safety-blue.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				&.digital-forms {
					&:after {
						background: url('/assets/img/icons/icon-digital-forms-blue.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				&.predictive-analytics {
					&:after {
						background: url('/assets/img/icons/icon-predictive-analytics-blue.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				&.asset-management {
					&:before {
						background: $color-blue;
					}
					&:after {
						background: url('/assets/img/icons/icon-asset-management-white.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				
				
				&.assets {
					&:before {
						background: $color-blue;
					}
					&:after {
						background: url('/assets/img/icons/icon-assets.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				&.financials {
					&:before {
						background: $color-blue;
					}
					&:after {
						background: url('/assets/img/icons/icon-financials.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				&.people {
					&:before {
						background: $color-blue;
					}
					&:after {
						background: url('/assets/img/icons/icon-people.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				&.reputation {
					&:before {
						background: $color-blue;
					}
					&:after {
						background: url('/assets/img/icons/icon-reputation.svg') center center no-repeat;
						background-size: .8em;
					}
				}
				&.worksites {
					&:before {
						background: $color-blue;
					}
					&:after {
						background: url('/assets/img/icons/icon-worksites.svg') center center no-repeat;
						background-size: .8em;
					}
				}
			}
		}
	}
}