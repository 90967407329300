.hero--resources {
	overflow: hidden;
	padding: 100px 0;
	.container {
		text-align: center;
		max-width: 1000px;

		.typography {
			color: #FFFFFF;

			&__title {
				font-size: $h1-font-size;
			}

			&__subheading {
				font-size: $h4-font-size;
			}
		}
	}
}

.resources-filter {
	position: absolute;
	transform: translate(-50%, -50%);
	left: 50%;
	width: 1000px;
	max-width: calc(100vw - 40px);
	border-radius: 50px;
	background-color: #fff;
	box-shadow: 0 5px 20px #0004;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: auto;

	.typography {
		font-size: $h5-font-size;
		color: #4180F6;
		font-weight: $font-weight-bold;
	}

	> * {
		margin: 0 32px;
		padding: 20px 0;
	}

	select {
		border: 0;
		background: none;
		flex-grow: 1;

		&:not(:focus) {
			color: #747474;
		}
	}
}

.resources {
	position:relative;
	padding:55px 0px 17px;
	margin-top: 80px;

	.hero + & {
		padding-top:0px;
	}

	@include media-breakpoint-up(md) {
		padding:48px 0px 80px;
	}

	&__title {
		margin:0px 0px 24px;
	}

	&__heading {
		max-width: 66%;
		display: block;
	}

	&__controls {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 30px 0;

		.pills {
			display: flex;
			flex-direction: row;

			.pill {
				padding: 5px 10px;
				border-radius: 5px;
				user-select: none;
				cursor: pointer;
				color: $text-muted;

				&:not(:last-child) {
					margin-right: 10px;
				}

				&.active {
					background-color: #F4F5F7;
					color: $dark;
				}
			}
		}

		a {
			color: $primary;
			text-decoration: underline;
		}
	}

	&__resources {
		@include make-row();

		&.hidden {
			display: none;
		}
	}

	&__resource {
		@include make-col-ready();
		@include make-col(12);
		padding: 6px;

		&, &:hover {
			text-decoration: none;
			color: inherit;
		}

		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}

		@include media-breakpoint-up(md) {
			@include make-col(4);
		}

		@include media-breakpoint-down(xs) {
			max-width:312px;
			min-width:312px;
		}
	}

	.resource {
		display:flex;
		flex-direction:column;
		position:relative;
		border: 10px solid transparent;
		transition: all 500ms ease;
		user-select: none;
		cursor: pointer;

		&:hover {
			box-shadow: 0 20px 40px -20px #eeea;
			transform: translateY(-2px);
			filter: brightness(100.5%);
			transition: all 150ms ease;
		}

		&:active {
			filter: brightness(99%);
			transform: scale(0.99);
		}

		&::before {
			margin-top: 100%;
			content: '';
		}

		@at-root a#{&} {
			text-decoration:none;
			color:inherit;
		}

		.resource-icon {
			height: auto;
		}

		.resource-image {
			object-fit: contain;
		}

		img {
			width:100%;
			height:244px;
			margin:0px 0px 30px;
			object-fit:cover;
		}

		.content {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			padding: 30px;
			border-radius: 20px;
			background-color: #F4F5F7;
		}

		h4 {
			line-height:lineheight(32, 24);
			margin-top: 20px;
			margin-bottom: 10px;
		}

		.type {
			color: #d2d3d5;
			font-size:fontsize(12);
			margin-bottom: auto;
		}

		small {
			margin: 0;
			font-size:fontsize(12);
			font-weight:$font-weight-bold;
			color:#D0D2D3;
		}

		span {
			bottom: 0;
			display: block;
			font-size:fontsize(12);
			font-weight:$font-weight-bold;
			letter-spacing:letterspacing(1.5, 12);
			text-transform: uppercase;
			color: #7D7D7D;
		}

		.icon {
			width: 70px;
			height: 70px;
			display: block;
			svg * { stroke: #2F75F6; }
			border-radius: 100%;
		}
	}
}

.industries {
	&__industry {
		display: flex;
		align-items: stretch;
		background-color: #F4F5F7;
		flex-wrap: nowrap;

		@include media-breakpoint-up(lg) {
			flex-direction: row;

			&:nth-child(odd) {
				flex-direction: row-reverse;
			}
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
		}


		img {
			height: 28vw;
			max-height: 50vh;
			object-fit: cover;
			height: auto;
		}

		img, .content {
			@include media-breakpoint-down(md) {
				width: 100vw;
			}

			@include media-breakpoint-up(lg) {
				width: 50vw;
			}
		}

		.content {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 40px;

			.inner {
				width: 500px;
				display: block;

				h3 {
					opacity: 0.25;
				}

				h2 {
					margin: 20px 0;
				}
			}

			.button {
				text-transform: uppercase;
				letter-spacing: 0.1ex;
				padding: 15px 30px;
				border-radius: 50px;
				margin-top: 20px;
				border: 1px solid #000;
				display: inline-block;
				color: inherit;
				font-weight: $font-weight-bold;
				font-size: 14px;

				&:hover {
					text-decoration: none;
				}
			}
		}

	}
}

.cs-categories {
	position: relative;
	display: block;
	width: 100%;
	text-align: right;
	padding-top: 1em;
	padding-bottom: 1em;
	
	.typography {
		display: inline;
		text-transform: uppercase;
	}
	
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;       /* Remove default arrow */
		background-image: none;   /* Add custom arrow */
		background-color: $color-orange;
		border-color: $color-orange;
		padding-left: 1em;
		line-height: 2em;
		outline: none;
		color: white;
		border-top-left-radius: 1.5em;
		border-top-right-radius: 1.5em;
		border-bottom-left-radius: 1.5em;
		border-bottom-right-radius: 1.5em;
	}
	.select-wrap {
		display: inline;
		position: relative;
		
		/*
		&:before {
			content: "";
			position: absolute;
			display: block;
			top: 50%;
			right: .5em;
			transform: translate(-.5em, -70%) rotate(45deg);
			width: 10px;
			height: 10px;
			border-right: 1px solid white;
			border-bottom: 1px solid white;
		}
		
		&:after {
			content: "";
			position: absolute;
			display: block
		}
		*/
		
		.decorator {
			position: absolute;
			display: block;
			top: 50%;
			right: .5em;
			transform: translate(-.5em, -70%) rotate(45deg);
			width: 10px;
			height: 10px;
			border-right: 1px solid white;
			border-bottom: 1px solid white;
		}
	}
	
	@include media-breakpoint-down(sm) {
		text-align: center;
		
		.typography {
			display: block;
			text-align: center;
			margin-bottom: .5em;
		}
		.select-wrap {
			text-align: center;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
