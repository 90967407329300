.btn-orange {
	@extend .btn;
	
	color: #fff;
	background-color: $color-orange;
	border-color: $color-orange;
	
	&:hover {
		color: #fff;
		background-color: lighten($color-orange, 10%);
		border-color: lighten($color-orange, 10%);
	}
}

.btn-outline {
	background-color: transparent !important;
	
	&.btn-white {
		color: #fff;
		border-color: #fff;
		
		&:hover {
			color: #fff;
			background-color: rgba(#fff, .15) !important;
		}
	}
	
	&.btn-orange {
		color: $color-orange !important;
		border-color: $color-orange;
		
		&:hover {
			color: $color-orange !important;
			background-color: rgba($color-orange, .15) !important;
		}
	}
	
	&.btn-blue {
		color: $color-blue !important;
		border-color: $color-blue;
		
		&:hover {
			color: $color-blue !important;
			background-color: rgba($color-blue, .15) !important;
		}
	}
	
	&.btn-blue-dark {
		color: $color-blue-dark !important;
		border-color: $color-blue-dark;
		
		&:hover {
			color: $color-blue-dark !important;
			background-color: rgba($color-blue-dark, .15) !important;
		}
	}
	
	&.btn-purple {
		color: $color-purple !important;
		border-color: $color-purple;
		
		&:hover {
			color: $color-purple !important;
			background-color: rgba($color-purple, .15) !important;
		}
	}
	
	&.btn-green {
		color: $color-green !important;
		border-color: $color-green;
		
		&:hover {
			color: $color-green !important;
			background-color: rgba($color-green, .15) !important;
		}
	}
	
	&.btn-green-light {
		color: $color-green-light !important;
		border-color: $color-green-light;
		
		&:hover {
			color: $color-green-light !important;
			background-color: rgba($color-green-light, .15) !important;
		}
	}
}

.btn-video {
	position: relative;
	padding-right: 2.2rem;
	
	&:before {
		content: "";
		position: absolute;
		display: block;
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		border-left: 8px solid white;
		top: 50%;
		right: 0;
		transform: translate(-1.1rem, -50%);
	}
	
	&:after {
		content: "";
		position: absolute;
		display: block;
		width: 22px;
		height: 22px;
		border: 1px solid white;
		border-radius: 100%;
		top: 50%;
		right: 0;
		transform: translate(-.75rem, -50%);
	}
}