.copy-tabs {
	position:relative;
	background:$dark;
	color:white;

	&__copy {
		padding-top:48px;
		padding-bottom:48px;

		h3 {
			margin:0px 0px 48px;
		}

		h2 {
			margin:0px 0px 40px;
		}

		@include media-breakpoint-up(md) {
			padding-top:80px;
			padding-bottom:80px;
			padding-left:calc(((100vw - #{map-get($container-max-widths, "md")}) / 2) + #{$grid-gutter-width/2});
			padding-right:calc(((100vw - #{map-get($container-max-widths, "md")}) / 2) + #{$grid-gutter-width/2});

			h3 {
				margin-bottom:40px
			}

			h2 {
				margin-bottom:80px;
			}
		}

		@include media-breakpoint-up(lg) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "lg")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(xl) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "xl")}) / 2) + #{$grid-gutter-width/2});
			padding-right:75px;
		}

		@include media-breakpoint-up(xxl) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "xxl")}) / 2) + #{$grid-gutter-width/2});
		}
	}

	&__nav {
		margin:0px 0px 26px;

		a {
			display:inline-block;
			padding:4.5px 15px;
			border-radius:4px;
			font-size:fontsize(16);
			text-decoration:none;
			color:#9C9C9C;

			&.active {
				background:white;
				color:$dark;
			}
		}
	}

	&__tabs {
		@include media-breakpoint-up(md) {
			font-size:fontsize(20);

			p {
				margin:22px 0px;
			}
		}
	}

	&__image {
		padding:0px;

		img {
			width:100%;
			height:auto;

			&:not(.active) {
				display:none;
			}

			@include media-breakpoint-up(lg) {
				height:100%;
				object-fit:cover;
			}
		}
	}
}