.contact-form {
	position:relative;
	padding:40px 0px;

	.hero.hero--theme-light + & {
		margin-top:-30px;
		padding-top:0px;
	}

	@include media-breakpoint-up(md) {
		padding:56px 0px;

		.hero.hero--theme-light + & {
			margin-top:-70px;
		}

		.container {
			max-width:1016px + $grid-gutter-width;
		}
	}

	&__copy,
	&__form {
		display:flex;
		flex-direction:column;
		justify-content:center;
		padding:32px 16px;
		border:1px solid #BEBEBE;
		border-radius:16px;

		@include media-breakpoint-up(md) {
			height:100%;
			padding:45px 40px;
		}
	}

	&__copy {
		@extend %typography--muted;
	}

	&__form {
		text-align:center;

		@include media-breakpoint-down(sm) {
			margin-top:12px;
		}
	}
}