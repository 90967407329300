.stickymenu {
	position:sticky;
	top:0px;
	left:0px;
	z-index:100;
	height:76px;
	margin:0px 0px -76px;
	padding:20px 0px;
	background: rgba(white, 0.85);
	//border-bottom: 1px solid #ccc;
	color: $body-color;
	
	& + section {
		margin-top: 56px;
		
		@include media-breakpoint-up(md) {
			margin-top: 76px;
		}
	}

	@include media-breakpoint-down(md) {
		height:56px;
		padding:17px 0px;
		margin:0px 0px -56px;
		
		nav {
			display: none;
		}
		
		&.inpage-menu-active {
			background: white;

			nav {
				display: block;
				transform:translate(0, 0);
			}
		}

		.container {
			max-width:100%;
		}
	}

	&__inner {
		display:flex;
		align-items:center;
	}

	h1, h2 {
		font-size:fontsize(16);
		margin: 0 25px;
		font-weight: 100;
		color: $color-orange;
		
		a {
			color: $color-orange !important;
			text-decoration: none !important;
		}

		.icon-wrap {
			display:inline-block;
			margin:-3px 16px 0px 0px;
			
			position: relative;
			&:before {
				content: "";
				position: absolute;
				display: block;
				width: 40px;
				height: 40px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background: $color-orange;
				border-radius: 100%;
				z-index: -1;
			}
		}

		@include media-breakpoint-down(md) {
			padding:0px 25px;
			font-size:fontsize(16);
		}

		@include media-breakpoint-down(sm) {
			padding:0px 5px;
		}
	}

	nav {
		a {
			display:block;
			font-size:fontsize(18);
			color:inherit;

			&.button {
				//@extend .btn;
				//@extend .btn-danger;
				@extend .btn-sm;
				@extend .btn-orange
			}
		}

		@include media-breakpoint-up(lg) {
			margin:0px 0px 0px auto;

			a {
				display:inline-block;
				margin:0px 15px;
				font-size:fontsize(12);
			}
		}

		@include media-breakpoint-down(md) {
			position:absolute;
			top:100%;
			left:0px;
			z-index:-1;
			width:100%;
			height:calc(100vh - 56px);
			padding:0px ($grid-gutter-width*0.5 + 5px);
			background: white;
			transition:transform 300ms ease;
			transform:translate(0, -100vh);

			a:not(.button) {
				padding:16px 0px;

				+ a:not(.button) {
					border-top:1px solid rgba(#D3D4D6, 0.5);
				}
			}

			a.button {
				width:100%;
				margin:16px 0px;
				padding-top:$btn-padding-y-lg;
				padding-bottom:$btn-padding-y-lg;
				font-size:fontsize(14);
			}
		}

		@include media-breakpoint-down(sm) {
			height:calc(100vh - 56px);
		}
	}
	
	@include media-breakpoint-down(md) {
		&:not(.inpage-menu-active) {
			nav {
				background: transparent !important;
				opacity: 0;
			}
		}
	}

	button {
		display:block;
		width:24px;
		height:24px;
		margin:0px 0px 0px auto;
		padding:4px;
		border:none;
		border-radius:0px;
		background:transparent;

		img {
			display:block;
		}

		@include media-breakpoint-up(lg) {
			display:none;
		}
	}
	
	&.products,
	&.industries {
		@include media-breakpoint-up(lg) {
			height: inherit;
		}
		
		h1, h2 {
			color: $color-charcoal;
			
			@include media-breakpoint-up(lg) {
				display: none;
			}
			@include media-breakpoint-down(lg) {
				padding: 0;
				margin-left: 15px;
			}
		}
		
		nav {
			@include media-breakpoint-up(lg) {
				position: relative;
				display: block;
				padding-right: 200px;
			}
			
			a {
				font-size:fontsize(15);
				color: inherit;
				font-weight: 700;
				color: $color-orange;
				margin: 0px 8px;
				
				&.button {
					color: white;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translate(0, -50%);
				}
			}
		}
	}
	
	&.products {
		
		@include media-breakpoint-up(lg) {
			min-height: 76px;
		}
	}
}