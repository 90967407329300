.section {
	&--general {
		padding-top: 40px !important;
		padding-bottom: 40px !important;
		
		@include media-breakpoint-up(md) {
			padding-top: 80px !important;
			padding-bottom: 80px !important;
		}
		
		& [class*='col'] {
			h2, h3, h4, h5, p, li {
				margin-bottom: 1rem;
			}
			
			& > :first-child {
				margin-top: 0;
			}
			& > :last-child {
				margin-bottom: 0;
			}
		}
	}

}