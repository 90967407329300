.wf-form__field {
	@extend .form-group;

	&--text,
	&--email {
		@extend .form-group--labelled;

		input {
			@extend .form-control;
		}

		&.error input {
			@extend .form-control.error;
		}
	}

	&--select {
		@extend .form-group--labelled;

		select {
			@extend .form-control;
		}

		&.error select {
			@extend .form-control.error;
		}
	}
}

.wf-checkbox {
	@extend %checkbox;

    [type="checkbox"] {
        @extend %checkbox__input;

        + label {
            @extend %checkbox__label;
            position:static;
            font-size:fontsize(12);
            color:inherit;

            a {
                color:inherit;
            }
        }

        &:checked + label {
            @extend %checkbox__label--active;
        }

        &.error + label {
            @extend %checkbox__label--error;
        }
    }
}

[name="wf-submit"] {
	@extend .btn;
	@extend .btn-primary;
}