@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Regular.eot');
  src: url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy-Regular.otf') format('opentype'),
  url('../fonts/Gilroy-Regular.woff') format('woff'),
  url('../fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Medium.eot');
  src: url('../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy-Medium.otf') format('opentype'),
  url('../fonts/Gilroy-Medium.woff') format('woff'),
  url('../fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Bold.eot');
  src: url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy-Bold.otf') format('opentype'),
  url('../fonts/Gilroy-Bold.woff') format('woff'),
  url('../fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}