.profiles {
	position:relative;
	padding:40px 0px;

	.stacking-tiles + & {
		padding-top:0px;
	}

	@include media-breakpoint-up(md) {
		padding:88px 0px;
	}

	@include media-breakpoint-up(lg) {
		&--3xGrid {
			padding-left:25%;
		}
	}

	&__title {
		margin:0px 0px 32px;

		@include media-breakpoint-up(lg) {
			.profiles--3xGrid & {
				float:left;
				width:25%;
				margin-left:-25%;
			}
		}
	}

	.container {
		padding-right:48px;
	}

	.row {
		margin:0px -25px -48px;

		.col-6 {
			padding:0px 25px 48px;
		}
	}

	&__tile {
		img {
			width:100%;
			height:auto;
			max-width:240px;
			margin:0px 0px 18px;
		}
	}
}