.site-footer {
  background: #ECEEF0 linear-gradient(to right, #ECEEF0, #FFFFFF);

  &__top {
    padding:20px 0px;

    @include media-breakpoint-up(md) {
      padding:80px 0px calc(40px - 2rem);
    }
  }

  &__bottom {
    display:flex;
    align-items:center;
    padding:1.75rem 0px;
    font-size:fontsize(12);
    color:#404041;

    @include media-breakpoint-down(sm) {
      flex-wrap:wrap;
    }
  }

  .col-12 {
    margin-bottom:2rem;
  }

  &__contact {
    @extend %typography--muted;

    a {
      color:inherit;
    }

    @include media-breakpoint-up(md) {
      font-size:fontsize(20);
    }
  }

  &__subscribe {
    color:$dark;

    @include media-breakpoint-up(md) {
      label {
        font-size:fontsize(20);
      }
    }
  }

  &__copyright {
    @include media-breakpoint-up(md) {
      display:inline-block;
    }

    @include media-breakpoint-down(sm) {
      order:2;
    }
  }

  &__menu {
    a {
      display:inline-block;
      margin:0px 10px;
      text-decoration:none;
      color:inherit;
    }

    @include media-breakpoint-up(md) {
      display:inline-block;
      margin:0px 0px 0px 27px;

      a {
        display:inline-block;
        margin:0px 27px 0px 0px;
      }
    }

    @include media-breakpoint-down(sm) {
      order:1;
      flex-basis:100%;
      margin:0px 0px 1rem;
    }
  }

  &__credit {
    order:3;
    margin-left:auto;
    color:#D0D2D3;

    a {
      color:inherit;
    }
  }
}
  
//
// 2022 footer update
//
.site-footer--2022 {
  color: white;
  position: relative;
  overflow: hidden;
  
  a {
	  color: white;
  }
  
  .site-footer__contact,
  .site-footer__contact h4,
  .site-footer__subscribe h4,
  .wf-form__field--email label {
	  color: white;
  }
  
  background: none;
  
  &:before {
		content: "";
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-image: linear-gradient(45deg, 
	  	#446FB6 0%, 
	  	#584DA0 62%,
	  	#49A9DF 100%
	  );
	  z-index: -2;
	 }
	
	&:after {
		content: "";
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: url('/assets/img/logomark.svg') left bottom no-repeat;
		background-size: contain;
		transform: scale(1.2);
		z-index: -1;
		opacity: .5;
	}
  
	[name=wf-submit] {
		background-color: transparent;
		border-color: white;
		color: white;
		
		&:hover {
			background-color: rgba(white, .2);
		}
	}
}

.footer-logo{
  max-height: 55px;
}
ul.footer-menu{
  list-style: none;
  padding: 0;
  display: inline-block;
}
ul.footer-menu li{
  display: inline-block;
}
.subscribe-form input{
    border: unset;
    border-bottom: 2px solid #D8D8D8;
    background-color: transparent;
    width: 100%;
}