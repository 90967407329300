.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;

    .modal-content {
      border-radius: .25rem;
      border-color: #e6eaec;
    }

    .btn-close {
      min-width: 1rem;
    }

    .modal-header {
      border-bottom: 0;
      padding: 0;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start;
      background: #f5f6fc;
      border-bottom: 1px solid #e6eaec;
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
      margin-bottom: 1.2rem;

      h4 {
        padding: 1rem 0 1rem 0.9rem;
  
        &+button{
          margin: 0.5rem 0.5rem 0 0;
          }
        }
      }
      
      .modal-body {
        margin: 0 1rem 1rem 1rem;
        padding: 0;
      }

      .form-control {
        border-top: 1px solid #ced4da;
        border-left: 1px solid #ced4da;;
        max-height: 2.4rem;
        border-radius: 0;
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
        border-right: 0;
        max-width: 25.25rem;
        color: #191919;
        border-color: #e5e5e5;
        font-weight: 400;
        font-size: .85rem;
        padding: .64rem .75rem;
        border-radius: .25rem;
        text-align: right;
      }

      .input-group-text {
        font-size: .7522123894rem;
        background: #fafafd;
        border-color: #e5e5e5;
        padding: .375rem .75rem;
      }

           
        .form-group {
          .form-control.error {
            border-color: #de1728;
            background: none;
          }
        }

        .btn.multiselect {
          text-align: left;
          border-right: 1px solid #ced4da;
          &:after {
            position: absolute;
            right: 0.8rem;
            top: 0.95rem;
            color: #191919;
          }
        }

        .form-element-wrap .btn-group {
          max-width: 100%;
          width: 100%;
        }

      }

    
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
    @include media-breakpoint-up(md) { 
      max-width: 500px;
      margin: 1.75rem auto;
    }

  }

  .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
  }

  .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;

  }

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
  }

  .modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: none;
  }