.fullwidth-media {
	position:relative;

	> a {
		text-decoration:none;
		color:inherit;
	}

	&__inner {
		display:flex;
		align-items:center;
		height:587px;
		background:rgba(black, 0.25);
		color:white;
		overflow:hidden;

		@include media-breakpoint-up(md) {
			height:598px;
		}
	}

	&__bg {
		&--desktop,
		&--mobile {
			position:absolute;
			top:0px;
			left:0px;
			z-index:-1;
			width:100%;
			height:100%;
			object-fit:cover;
		}

		@include media-breakpoint-up(md) {
			&--mobile {
				display:none;
			}
		}

		@include media-breakpoint-down(sm) {
			&--desktop {
				display:none;
			}
		}
	}

	&__overlay {
		@include media-breakpoint-up(lg) {
			padding:0px 100px;
		}
	}

	&__link {
		display:block;
		margin:-10px 0px 0px;
		padding:0px;
		border:none;
		border-radius:0px;
		background:transparent;
		font-weight:$font-weight-light;
		color:inherit;
		cursor:pointer;

		@include media-breakpoint-up(md) {
			margin:37px 0px 0px;
			font-size:fontsize(20);
		}

		&:after {
			content:'';
			display:inline-block;
			width:78px;
			height:78px;
			margin:0px 0px 0px 30px;
			vertical-align:middle;
		}

		&--video:after {
			background:url('../img/fullwidth-media/video.svg') no-repeat center/contain;
		}

		&--download:after {
			background:url('../img/fullwidth-media/download.svg') no-repeat center/contain;
		}
	}
}