.info-cards {
	position:relative;
	padding:32px 0px 10px;
	background:#D0D2D3;

	@include media-breakpoint-up(md) {
		padding:56px 0px 48px;
	}

	&__title {
		margin:0px 0px -8px;
	}

	&__cards {
		display:flex;
		padding:40px 0px 40px $grid-gutter-width/2;
		overflow-x:auto;
		overflow-y:hidden;

		@include media-breakpoint-up(sm) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "sm")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(md) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "md")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(lg) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "lg")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(xl) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "xl")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(xxl) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "xxl")}) / 2) + #{$grid-gutter-width/2});
		}
	}

	&__card {
		position:relative;
		max-width:270px;
		min-width:270px;
		margin:0px 8px 0px 0px;

		&__front {
			display:flex;
			flex-direction:column;
			height:456px;
			padding:16px 16px;
			background:$dark url('../img/info-cards/front-bg.png') no-repeat center/cover;
			color:white;

			h3 {
				height:$headings-line-height*2em;
			}

			img {
				width:143px;
				height:auto;
				margin:auto;
			}

			button {
				display:inline-block;
				margin:0px;
				padding:0px;
				border:none;
				border-radius:0px;
				background:transparent;
				text-align:left;
				text-decoration:underline;
				color:$secondary;
				cursor:pointer;
			}
		}

		&__back {
			position:absolute;
			top:0px;
			left:0px;
			width:100%;
			height:100%;
			margin:0px 0px;
			padding:16px 16px;
			background:white;
			box-shadow:0px 2px 29px rgba(black, 0.23);
			opacity:0;
			pointer-events:none;
			$transition-function: cubic-bezier(.57,.09,.4,1.01);
			transition:opacity 150ms ease, width 100ms $transition-function, height 100ms $transition-function, padding 100ms $transition-function, margin 100ms $transition-function;

			button {
				display:block;
				position:absolute;
				top:0px;
				right:4px;
				width:32px;
				height:32px;
				margin:0px;
				padding:0px;
				border:none;
				border-radius:32px;
				background:rgba($primary, 0.2) url('../img/icons/info-card-close.svg') no-repeat center/11px;
				font-size:0;
				line-height:1;
				transition:top 100ms $transition-function, right 100ms $transition-function;
			}

			h3 {
				margin-bottom:20px;
			}
		}

		@include media-breakpoint-up(md) {
			max-width:424px;
			min-width:424px;
			margin:0px 24px 0px 0px;

			&__front {
				height:600px;
				padding:40px 40px;

				img {
					width:172px;
				}

				button {
					display:none;
				}
			}

			&__back {
				padding:40px 40px;

				button {
					display:none;
				}

				h3 {
					margin-bottom:40px;
				}
			}

			@include hover-focus {
				.info-cards__card__back {
					width:calc(100% + 16px);
					height:calc(100% + 32px);
					margin:-16px -8px;
					padding:56px 48px;
					opacity:1;
					pointer-events:auto;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			&.active {
				.info-cards__card__back {
					width:calc(100% + 8px);
					height:calc(100% + 16px);
					margin:-8px -4px;
					padding:24px 20px;
					opacity:1;
					pointer-events:auto;

					button {
						top:8px;
						right:8px;
					}
				}
			}
		}
	}
}