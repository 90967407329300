.pardot-contact-form {

}

#pardot-form {
    
    .field-label {
        font-size: .75rem;
        color: #717273;
        margin-bottom: -10px;
        padding-left: 0;
        padding-top: 0;
    }
    
    .text , .select {
        display: block;
        width: 100%;
        padding: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 2;
        color: #323232;
        background-color: transparent;
        background-clip: padding-box;
        border-bottom: 1px solid #D0D2D3;
        border-radius: 0;
        margin-top: 10px
    }
    
    .select {
        margin: 0;
        padding-top: 10px;
    }
    
    .form-field {
        margin-bottom: 15px;
        padding: 0px;

    }

    p.no-label {
        margin-left: 0;
        margin-top: -15px;
    }

    .errors {
        background: none;
        margin-bottom: 1em;
        font-size: .875rem;
        color: #FF1442;
        padding: 0;
    }

    .error {
        font-size: .875rem;
        color: #FF1442;

        .text , .select{
            border-color: #FF1442;
            background-color: rgba(255,20,66,.06);
        }

    }
}

.pardot-container {
    min-height: 880px;
    padding: 40px 30px;
}