.blog-posts {
	position:relative;
	padding:55px 0px 17px;
	margin-top: 80px;

	.hero + & {
		padding-top:0px;
	}

	@include media-breakpoint-up(md) {
		padding:48px 0px 80px;
	}

	&__title {
		margin:0px 0px 24px;
	}

	&__posts {
		@include make-row();
	}

	&__post {
		@include make-col-ready();
		@include make-col(12);
		padding:0px 6px;

		&, &:hover {
			text-decoration: none;
			color: inherit;
		}

		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}

		@include media-breakpoint-up(lg) {
			@include make-col(4);
		}

		@include media-breakpoint-down(xs) {
			max-width:312px;
			min-width:312px;
		}
	}
}

.blog-post {
	display:flex;
	flex-direction:column;
	position:relative;
	padding:14px 14px 25px;
	margin-bottom: 20px;

	@at-root a#{&} {
		text-decoration:none;
		color:inherit;
	}

	img {
		width:100%;
		height:244px;
		margin:0px 0px 30px;
		object-fit:cover;
	}

	h4 {
		line-height:lineheight(32, 24);
	}

	small {
		margin: 0;
		font-size:fontsize(12);
		font-weight:$font-weight-bold;
		color:#D0D2D3;
	}

	.links, .action {
		margin-top: 16px;
	}

	.links a, .action {
		display: block;
		font-size:fontsize(12);
		font-weight:$font-weight-bold;
		letter-spacing:letterspacing(1.5, 12);
		text-transform: uppercase;
		color: $primary;
		padding: 6px 0;
		
		&:hover {
			text-decoration: none;
		}

		svg {
			* { stroke: currentColor; }
			vertical-align: middle;
			margin-bottom: 2px;
			margin-right: 2ex;
			width: 16px;
			height: 16px;
		}

		&.gray {
			color: #7D7D7D;
		}
	}

	.action {
		color: #7D7D7D;
	}
	
	&.customer-story {
		img {
			width:100%;
			height:auto;
			margin:0px 0px 30px;
			object-fit: inherit;
		}
	}
}
