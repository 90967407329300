a{
    -moz-transition: background-color 0.2s ease-in-out 0s,color 0.2s ease-in-out 0s;
    -o-transition: background-color 0.2s ease-in-out 0s,color 0.2s ease-in-out 0s;
    -webkit-transition: background-color 0.2s ease-in-out 0s,color 0.2s ease-in-out 0s;
    transition: background-color 0.2s ease-in-out 0s,color 0.2s ease-in-out 0s;
    color: #191919;
    font-weight: 400;
    
    &:not([href="javascript:void(0);"]):hover  {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }

  h1 {
    padding: 1rem 0 0;
    font-size: 1.3rem;
    margin: 0;
    font-weight: 500;
    line-height: 1.8rem;
  }

  h2{
    line-height: 1.5;
  } 

  h3 {
    font-size: 1.1rem;
    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
    }
  }

  h4 {
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
  }
  .h5 , h5 {
      font-size: 0.9rem;
      line-height: 1.5;
  }