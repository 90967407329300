.banner-carousel {
	position: relative;

	&__one {
		position:relative;
		height: 598px !important;

		@media screen and (max-width:$screen-sm-max) {
			height: 100vh !important;
		}

		.container{
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
		}

		h2 {
			font-size: 64px;
			color: #FFFFFF;
			letter-spacing: 0;
			line-height: 80px;
			max-width: 750px;

			@media screen and (max-width:$screen-md-max) {
				// margin-top: 150px;
			}

			@media screen and (max-width:$screen-sm-max) {
				font-size: 44px;
				line-height: 52px;
			}
		}

		a {
			margin-top: 40px;
			color: #000;
			border-color: white;
			background-color: white;
			max-width: 220px;

			@media screen and (max-width:$screen-sm-max) {
				margin-top: 33px;

			}

		}
	}

	&__bg {
		&--desktop,
		&--mobile {
			position:absolute;
			top:0px;
			left:0px;
			z-index:-1;
			width:100%;
			height:100%;
			object-fit:cover;
		}

		&--desktop {
			@include media-breakpoint-down(sm) {
				display:none !important;
			}
		}

		&--mobile {
			@include media-breakpoint-up(md) {
				display:none !important;
			}
		}
	}

	.slick-dots {
		list-style: none;
		padding: 0;
		line-height: 1;
		position: relative;
		bottom: 58px;

		li {
			margin: 0 4px;
			padding: 0;
			display: inline-block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: white;
			transition: all 300ms ease;
			cursor: pointer;

			&.slick-active,
			&:hover {
				background: rgb(43, 236, 167);
			}

			button {
				display: none;
			}
		}

		@media screen and (max-width: screen-xs-max) {
			bottom: 3vw;

			li {
				margin: 0 2px;
				width: 7px;
				height: 7px;
			}
		}
	}

	.slick-arrow {
		position: absolute;
		background: transparent;
		z-index: 1;
		font-size: 0;
		border: 0;
		outline: 0;

		&:hover {
			cursor: pointer;
		}

		@media screen and (max-width:$screen-sm-max) {
			display: none;
		}
	}

	.slick-next {
		right: 10px;
		top: 50%;



		&:before {
			content: url("../img/icons/arrow-right-hover.svg");
		}
	}

	.slick-prev {
		left: 10px;
		top: 50%;



		&:before {
			content: url("../img/icons/arrow-left-hover.svg");
		}
	}

	.slick-prev.hidden,
	.slick-next.hidden {
		opacity: 0;
		pointer-events: none;
	}


}