.accordion {
	position:relative;
	padding:40px 0px 48px;

	.contact-form + & {
		padding-top:0px;
	}

	@include media-breakpoint-up(md) {
		padding:56px 0px 116px;

		.container {
			max-width:1016px + $grid-gutter-width;
		}
	}

	&__title {
		margin:0px 0px 16px;
	}

	&__panel {
		padding:0px 24px;
		border:1px solid #BEBEBE;
		border-radius:8px;
		background:white;

		& + & {
			margin-top:12px;
		}

		@include media-breakpoint-up(md) {
			border-radius:16px;
		}
	}

	&__toggle {
		display:flex;
		align-items:center;
		width:100%;
		margin:0px;
		padding:25px 0px;
		border:none;
		border-radius:0px;
		background:transparent;
		font-size:fontsize(20);
		font-weight:$font-weight-bold;
		text-align:left;

		&:after {
			content:'';
			display:block;
			width:16px;
			height:16px;
			margin:0px 0px 0px auto;
			background:url('../img/icons/accordion-toggle.svg') no-repeat center/contain;
		}
	}

	&__body {
		padding:31px 0px;
		border-top:1px solid #BEBEBE;
	}
}