.card-link {
	font-weight: 600;
	color: $color-orange;
	margin-bottom: 1rem;
	
	@include media-breakpoint-up(lg) {
		margin-bottom: 0;
	}
	
	&:hover {
		color: lighten($color-orange, 10%);
	}
}