.icon-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	
	li {
		position: relative;
		display: block;
		
		@include media-breakpoint-up(md) {
			margin-bottom: 2rem !important;
		}
		
		a {
			color: $color-blue;
		}
		
		h2, h3 {
			a {
				color: $color-charcoal;
				
				&:hover {
					color: $color-blue;
				}
			}
		}
		
		&.product {
			padding-left: 100px;
			
			@include media-breakpoint-up(md) {
				margin-bottom: 3rem !important;
			}
			
			.icon {
				position: absolute;
				display: block;
				width: 60px;
				height: 60px;
				top: .25rem;
				left: 0;
				
				& > img {
					height: 100%;
					width: 100%;
					object-fit: contain;
				}
				
				&.access-control {
					width: 80px;
					height: 80px;
				}
			}
			
			@include media-breakpoint-down(md) {
				padding-left: 50px;
				
				.icon {
					width: 30px;
					height: 30px;
				}
			}
		}
		
		&.icon {
			padding-left: 80px;
			
			&:before {
				content: "";
				position: absolute;
				width: 60px;
				height: 60px;
				top: .15rem;
				left: 0;
				//transform: translate(0, -50%);
				background-color: $color-blue-dark;
				border-radius: 100%;
				
				background-image: url('/assets/img/icons/icon-default.svg');
				background-position: center center;
				background-repeat: no-repeat;
				background-size: 40px;
				
				@include media-breakpoint-down(md) {
					background-size: 20px;
				}
			}
			
			@include media-breakpoint-down(md) {
				padding-left: 50px;
				
				&:before {
					width: 30px;
					height: 30px;
				}
			}
			
			/*
			&.workforce-management {
				&:before {
					background-image: url('/assets/img/icons/icon-workforce-management-white.svg');
				}
			}
			&.elearning {
				&:before {
					background-image: url('/assets/img/icons/icon-elearning-white.svg');
				}
			}
			&.solo {
				&:before {
					background-image: url('/assets/img/icons/icon-solo-white.svg');
				}
			}
			&.digital-forms {
				&:before {
					background-image: url('/assets/img/icons/icon-digital-forms-white.svg');
				}
			}
			*/
			&.assets {
				&:before {
					background-image: url('/assets/img/icons/icon-assets.svg');
				}
			}
			&.financials {
				&:before {
					background-image: url('/assets/img/icons/icon-financials.svg');
				}
			}
			&.people {
				&:before {
					background-image: url('/assets/img/icons/icon-people.svg');
				}
			}
			&.reputation {
				&:before {
					background-image: url('/assets/img/icons/icon-reputation.svg');
				}
			}
			&.access-control {
				&:before {
					background-image: url('/assets/img/icons/icon-access-control.svg');
				}
			}
			&.asset-management {
				&:before {
					background-image: url('/assets/img/icons/icon-asset-management-white.svg');
				}
			}
			&.digital-forms {
				&:before {
					background-image: url('/assets/img/icons/icon-digital-forms.svg');
				}
			}
			&.solo {
				&:before {
					background-image: url('/assets/img/icons/icon-solo.svg');
				}
			}
			&.safety {
				&:before {
					background-image: url('/assets/img/icons/icon-safety.svg');
				}
			}
			&.elearning {
				&:before {
					background-image: url('/assets/img/icons/icon-elearning.svg');
				}
			}
			&.workforce-management {
				&:before {
					background-image: url('/assets/img/icons/icon-workforce-management.svg');
				}
			}
			&.worksites {
				&:before {
					background-image: url('/assets/img/icons/icon-worksites.svg');
				}
			}
			&.vaccine-management {
				&:before {
					background-image: url('/assets/img/icons/icon-vaccine-management-white.svg');
				}
			}
			&.predictive-analytics {
				&:before {
					background-image: url('/assets/img/icons/icon-predictive-analytics-white.svg');
				}
			}
			
			//
			// BLUE ICONS (PRODUCTS)
			//
			&.blue {
				
				&:before {
					background-color: transparent;
					background-image: url('/assets/img/icons/icon-default-blue.svg');
					transform: translate(0, -20%) scale(1.5);
				}
				
				&.access-control {
					&:before {
						background-image: url('/assets/img/icons/icon-access-control-blue.svg');
					}
				}
				&.assets {
					&:before {
						background-image: url('/assets/img/icons/icon-assets-blue.svg');
					}
				}
				&.asset-management {
					&:before {
						background-image: url('/assets/img/icons/icon-asset-management-blue.svg');
					}
				}
				&.digital-forms {
					&:before {
						background-image: url('/assets/img/icons/icon-digital-forms-blue.svg');
					}
				}
				&.solo {
					&:before {
						background-image: url('/assets/img/icons/icon-solo-blue.svg');
					}
				}
				&.safety {
					&:before {
						background-image: url('/assets/img/icons/icon-safety-blue.svg');
					}
				}
				&.elearning {
					&:before {
						background-image: url('/assets/img/icons/icon-elearning-blue.svg');
					}
				}
				&.workforce-management {
					&:before {
						background-image: url('/assets/img/icons/icon-workforce-management-blue.svg');
					}
				}
				&.predictive-analytics {
					&:before {
						background-image: url('/assets/img/icons/icon-predictive-analytics-blue.svg');
					}
				}
			}
		}
	}
}