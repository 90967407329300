//
// Color system
//
$color-blue:        #0273FF; // blue bright
$color-blue-dark:   #426FB6;
$color-purple:      #584DA0; // purple
$color-green-light: #2BECA7; // green light
$color-green:       $green;  // green (bootstrap)
$color-cyan:        $cyan;   // blue light (bootstrap)
$color-yellow:      $yellow; // yellow (bootstrap)
$color-orange:      #EE5525; // orange
$color-red:         #FF1442; // red
$color-silver:      #F4F5F7; // silver
$color-midnight:    #001928; // blue midnight
$color-charcoal:    #323232; // charcoal