.copy-links {
	position:relative;
	padding:85px 0px;

	.hero--theme-light + & {
		padding-top:0px;
	}

	@include media-breakpoint-up(md) {
		padding:75px 0px;
	}

	&__inner {
		@include make-row();

		@include media-breakpoint-up(xl) {
			margin:0px -35px;
		}
	}

	&__copy,
	&__links {
		@include make-col-ready();
		@include make-col(12);

		@include media-breakpoint-up(xl) {
			padding:0px 35px;
		}
	}

	&__copy {
		@extend %typography--muted;
		
		@include media-breakpoint-up(lg) {
			@include make-col(37,100);
		}
	}

	&__links {
		> .row {
			margin:0px -5px -10px;
		}

		@include media-breakpoint-up(lg) {
			@include make-col(63,100);
		}

		@include media-breakpoint-down(md) {
			margin-top:40px;
		}
	}

	&__link {
		padding:0px 5px 10px;

		a {
			display:block;
			position:relative;
			min-height:106px;
			padding:20px 20px 20px 70px;
			border:1px solid #BEBEBE;
			border-radius:16px;
			text-decoration:none;
			color:inherit;
		}

		img {
			width:40px;
			height:40px;
			margin:-3px 0px 0px -55px;
			object-fit:contain;
		}

		h3 {
			min-height:$headings-line-height*2em;
		}

		small {
			margin:19px 0px 0px;
			font-size:fontsize(12);
			color:#BEBEBE;
		}

		@include media-breakpoint-up(sm) {
			a {
				min-height:238px;
				padding:20px 24px;
				overflow:hidden;


				&:before {
					content:'';
					position:absolute;
					right:-230%;
					bottom:-190%;
					width:300%;
					height:300%;
					background:radial-gradient(rgba(#5040D4, 0.83) 0%, rgba(#5040D4, 0.42) 20%, rgba(#5040D4, 0) 70%);
					opacity:0;
					transition:opacity 600ms ease;
				}

				@include hover-focus {
					&:before {
						opacity:1;
					}
				}
			}

			img {
				width:48px;
				height:48px;
				margin:0px 0px 37px;
			}
		}

		@include media-breakpoint-down(xs) {
			img {
				float:left;
			}

			small {
				display:none;
			}
		}
	}
}