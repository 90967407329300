.cta-banner {
	position:relative;
	padding:24px 0px;

	.two-column-features + & {
		padding-top:0px;
	}

	@include media-breakpoint-up(md) {
		padding:120px 0px;

		.container {
			max-width:928px + $grid-gutter-width;
		}
	}

	&__inner {
		padding:40px 16px 30px;
		border-radius:16px;
		background:$dark url('../img/cta-banner/bg.png') no-repeat top center/cover;
		color:white;

		@include media-breakpoint-up(md) {
			display:flex;
			align-items:center;
			padding:40px 60px;
		}

		@include media-breakpoint-down(sm) {
			text-align:center;
		}
	}

	&__copy {
		max-width:500px;
	}

	&__links {
		@include media-breakpoint-up(md) {
			margin-left:auto;
		}

		@include media-breakpoint-down(sm) {
			margin-top:32px;
		}
	}
}