.modal-content {
	border-radius:16px;

	> .close {
		position:absolute;
		top:20px;
		right:20px;
		z-index:10;
		width:36px;
		height:36px;
		border-radius:36px;
		background:rgba($primary, 0.2);
		opacity:1;

		#fullwidthVideoModal & {
			top:7px;
			right:7px;
		}

		img {
			display:inline-block;
			width:12px;
			height:12px;
			margin-top:-3px;
			margin-right:-1px;
		}
	}
}