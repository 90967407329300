.copy-image {
	position:relative;

	.copy-image + & {
		padding-top:0px;
	}

	@include media-breakpoint-up(md) {
		padding:120px 0px;

		.separator--line + & {
			padding-top:60px;
		}
	}

	&__inner {
		@include make-row();
		margin:0px 0px;
	}

	&__copy,
	&__image {
		@include make-col-ready();
		@include make-col(12);
		padding:0px;

		@include media-breakpoint-up(md) {
			@include make-col(6);
		}
	}

	&__copy {
		@extend %typography--muted;
		align-self:center;
		padding:0px $grid-gutter-width/2;

		@include media-breakpoint-up(md) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "md")}) / 2) + #{$grid-gutter-width/2});

			.copy-image--copyImage & {
				order:1;
			}

			.copy-image--imageCopy & {
				order:2;
			}
		}

		@include media-breakpoint-up(lg) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "lg")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(xl) {
			padding-right:128px;
			padding-left:calc(((100vw - #{map-get($container-max-widths, "xl")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(xxl) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "xxl")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-down(sm) {
			padding-top:40px;
			padding-bottom:48px;

			.copy-image--mobile-copyImage & {
				order:1;
			}

			.copy-image--mobile-imageCopy & {
				order:2;
			}
		}
	}

	&__image {
		img {
			width:100%;
			height:auto;
		}

		@include media-breakpoint-up(md) {
			.copy-image--copyImage & {
				order:2;
			}

			.copy-image--imageCopy & {
				order:1;
			}
		}

		@include media-breakpoint-down(sm) {
			.copy-image--mobile-copyImage & {
				order:2;
			}

			.copy-image--mobile-imageCopy & {
				order:1;
			}
		}
	}
}