.base-contact-form {
    text-align:center;
}

.btn {
    min-width: 176px;
    font-size: fontsize(14);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: letterspacing(2.13, 16);
    transition: border 300ms ease, background 300ms ease, color 300ms ease;

    &-lg {
        min-width: 239px;
        font-size: fontsize(16);
    }

    &-sm {
        min-width: 135px;
        font-size: fontsize(12);
    }

    &-primary {
        background-color:$primary;
        border-color:$primary;
        color:white;

        @include hover-focus {
            background-color:#0293FF;
            border-color:#0293FF;
        }

        &.btn-lg {
            @extend .btn-primary-alt;
        }
    }

    &-primary-alt {
        background-color:$secondary;
        border-color:$secondary;
        color:black;

        @include hover-focus {
            background-color:#48FFBD;
            border-color:#48FFBD;
            color:black;
        }
    }

    &-secondary {
        background-color:transparent;
        border-color:$darkgrey;
        color:$darkgrey;

        @include hover-focus {
            background-color:$darkgrey;
            border-color:$darkgrey;
            color:white;
        }
    }

    &-light {
        background-color:transparent;
        border-color:white;
        color:white;

        @include hover-focus {
            background-color:white;
            border-color:white;
            color:$darkgrey;
        }
    }
}

.form-control {
    height:52px;
    padding-top:12px;
    border-top:none;
    border-left:none;
    border-right:none;
    box-shadow:none !important;
    
    &::placeholder {
        opacity: 1 !important;
    }

    &.error {
        border-color:$danger;
        background-color:rgba($danger, 0.06);
    }
}

select.form-control {
    appearance:none;
    background-image:url('../img/icons/dropdown-arrow.svg');
    background-repeat:no-repeat;
    background-size:16px;
    background-position:right center;
}

.form-group--labelled {
    position:relative;

    label {
        position:absolute;
        top:18px;
        left:0px;
        font-size:fontsize(16);
        color:#989D9F;
        pointer-events:none;
        transition:top 300ms ease, color 300ms ease, font 300ms ease;
    }

    &.focussed {
        label {
            top:0px;
            font-size:fontsize(12);
            color:#717273;
        }
    }
}

%radio {
    position: relative;
    padding: 0px 0px 0px 35px !important;

    &__input {
        position: absolute;
        opacity: 0;
    }

    &__label {
        &:before {
            content: '';
            display: block;
            position: relative;
            float: left;
            top: 4px;
            width: 20px;
            height: 20px;
            margin: 0px 0px 0px -35px;
            background: white;
            border: 5px solid white;
            border-radius: 20px;
            line-height: 1;
        }

        &--active {
            &:before {
                background: black;
            }
        }

        &--focussed {
            &:before {
                border-color: $secondary;
            }
        }
    }
}

%checkbox {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 32px !important;

    &__input {
        position: absolute;
        opacity: 0;
    }

    &__label {
        &:before {
            content: '';
            display: block;
            position: relative;
            float: left;
            top: 0px;
            width: 24px;
            height: 24px;
            margin: 0px 0px 0px -32px;
            border: 1px solid black;
            border-radius:24px;
            line-height: 1;
        }

        &--active {
            &:before {
                background:url('../img/icons/tick.svg') no-repeat center/12px;
            }
        }

        &--error {
            &:before {
                border-color:$danger;
            }
        }
    }
}

.form-checkbox {
    @extend %checkbox;

    [type="checkbox"] {
        @extend %checkbox__input;

        + span {
            @extend %checkbox__label;
            font-size:fontsize(12);

            a {
                color:inherit;
            }
        }

        &:checked + span {
            @extend %checkbox__label--active;
        }

        &.error + span {
            @extend %checkbox__label--error;
        }
    }
}

.errors {
    display:block;
    margin:0px;
    padding:0px;
    text-align:center;

    li {
        display:block;
        margin:0px;
        padding:0px;
        list-style:none;
        font-size:$font-size-sm;
        color:$danger;
    }
}

#field-form4 {

    input[name=Domain] {
        width: 60%;
        display: inline-block;
        float: left;
    }
    #container_Domain span.domainprefix {
        width: 40%;
        display: inline-block;
        height: 52px;
        text-align: left;
        padding-top: 15px;
        padding-left: 20px;
    }

    select[name=auState] {
        display: none;
    }

}

