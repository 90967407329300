$color-pagination-link: #666;

.c-pagination {
	
	a {
		color: $color-pagination-link;
		
		&:hover {
			color: $color-orange;
			
			&:before,
			&:after {
				border-color: $color-orange;
			}
		}
	}
	
	.first,
	.last,
	.prev,
	.next {
		position: relative;
		overflow: hidden;
		text-indent: -9999em;
		
		&:before {
			content: "";
			position: absolute;
			display: block;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: .5em;
			height: .5em;
		}
	}
	
	.first,
	.last {
		min-width: 2em;
		
		&:after {
			content: "";
			position: absolute;
			display: block;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: .5em;
			height: .5em;
		}
	}
	
	.first,
	.prev {
		&:before {
			border-top: 2px solid $color-pagination-link;
			border-left: 2px solid $color-pagination-link;
			transform: translate(-75%, -50%) rotate(-45deg);
		}
	}
	.last,
	.next {
		&:before {
			border-top: 2px solid $color-pagination-link;
			border-right: 2px solid $color-pagination-link;
			transform: translate(-75%, -50%) rotate(45deg);
		}
	}
	
	.first {
		&:after {
			border-top: 2px solid $color-pagination-link;
			border-left: 2px solid $color-pagination-link;
			transform: translate(25%, -50%) rotate(-45deg);
		}
	}
	.last {
		&:before {
			transform: translate(-100%, -50%) rotate(45deg);
		}
		&:after {
			border-top: 2px solid $color-pagination-link;
			border-right: 2px solid $color-pagination-link;
			transform: translate(0, -50%) rotate(45deg);
		}
	}
	.prev {
		&:before {
			transform: translate(-25%, -50%) rotate(-45deg);
		}
	}
}