.search-results {
	padding:40px 0px;

	@include media-breakpoint-up(md) {
		padding:58px 0px 120px;
	}

	.container {
		@include media-breakpoint-up(xl) {
			max-width:1014px + $grid-gutter-width;
		}
	}

	&__title {
		margin-bottom:30px;

		@include media-breakpoint-up(md) {
			margin-bottom:98px;
		}
	}

	&__type-nav {
		margin-bottom:20px;

		@include media-breakpoint-up(md) {
			margin-bottom:44px;
		}

		a {
			display:inline-block;
			margin-right:20px;
			padding:7px 0px;
			border-radius:3px;
			color:#7D7D7D;

			&.active {
				padding:7px 15px;
				background:#F0ECEE;
				color:#001928;
			}
		}
	}

	&__results {
		display:block;
		margin:0px;
		padding:0px;
		list-style:none;

		> li {
			display:block;
			margin:0px;
			padding:0px;
		}
	}

	&__result {
		display:block;
		padding:7px 0px 40px;
		border-top:1px solid #CCCCCC;
		color:inherit;

		@include hover-focus {
			text-decoration:none;
			color:inherit;
		}

		@include media-breakpoint-up(md) {
			display:flex;
		}

		&__meta {
			min-width:207px;
			max-width:207px;

			small {
				font-size:fontsize(12);
				letter-spacing:letterspacing(2, 12);
				text-transform:uppercase;
				color:$primary;
			}
		}

		&__desctiption {
			flex-grow:1;

			p {
				margin-top:4px;
			}
		}
	}

	&__pagination {
		text-align:center;

		@include media-breakpoint-up(md) {
			margin-top:40px;
		}

		a,
		span {
			display:inline-block;
			padding:7px 0px;
			border-radius:3px;
			color:#7D7D7D;

			+ a,
			+ span {
				margin-left:17px;
			}
		}

		.current {
			padding:7px 12px;
			background:#F0ECEE;
			color:#001928;
		}
	}
}