.alert {
	&--banner {
		position:relative;
		z-index:300;
		margin:0px;
		border-radius:0px;
		text-align:center;

		> * {
			display:inline-block;
		}

		&__cta {
			margin-left:6px;
			text-decoration:none !important;
			color:inherit !important;

			span {
				text-decoration:underline;
			}

			svg {
				display:inline-block;
				margin-left:6px;
				stroke:currentColor;
				vertical-align:middle;
			}
		}
	}

	&-info {
		background:#5040D4;
		border-color:#5040D4;
		color:white;
	}
}