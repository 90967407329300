.eify-login {
    margin-top: 2rem;


    .smaller-content-wrap {
        padding: 2rem 0 1rem;
    }

    .boundary-box {
        box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
        padding: 2rem 1rem 3rem;
        position: relative;
        -moz-transition: all 0.2s ease-in-out 0s;
        -o-transition: all 0.2s ease-in-out 0s;
        -webkit-transition: all 0.2s ease-in-out 0s;
        transition: all 0.2s ease-in-out 0s;
        overflow: hidden;
        background: #fff !important;

        &.show-forgot-password{
            .login-track {
                margin-left: -100%;
            }
        }

    }


    .login-track {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        width: 200%;
        -moz-transition: all 0.4s ease-in-out 0s;
        -o-transition: all 0.4s ease-in-out 0s;
        -webkit-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
        position: relative;

    }

    .form-group {

        margin: 0;
        max-width: 25.25rem;

        .form-element-wrap {
            position: relative;
            width: 100%;
        }

        &.help-link {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-wrap: wrap;
            -moz-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between;
            margin-top: 1.7rem;
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }

    .form-control {
        max-width: 25.25rem;
        color: #191919;
        border-color: #e5e5e5;
        font-weight: 400;
        font-size: .85rem;
        padding: .64rem .75rem;
        border-radius: .25rem;
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        max-height: 2.4rem;

    }

    form {
        &.login-form {

            .flow-btns {
            margin-top: 0.5rem;
            border-top: 0;
            text-align: center;
            padding-top: 1rem;
        
            .primary-actions {
                width: 100%;
                .btn {
                    width: 100%;
                }
            }


            }
        }

    }

    .login , .forgot-wrap{
        margin: 0 auto;
        width: 23.6rem;
        padding: 0 1.5rem 0;
        max-width: 23.6rem;
        background: #fff;
        border-radius: .25rem;
        
        h1 {
            font-size: 1.35rem;
            padding-top: 0;
            max-width: 100%;
            line-height: 2.2rem;

            a{
                white-space: nowrap;
                font-weight: 400;
                font-size: .85rem;
                color: #191919;

                i {
                    font-size: 1rem;
                    opacity: 0.5;
                }

            }
        }

        .content-header {
            max-width: 990px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 1rem;
            padding-right: 1rem;
            padding: 0 0 1.5rem 0 !important;
            width: 100%;
        }

    }

    .login-wrap, .forgot-wrap {
        min-width: 50%;
    }

    .forgot-password, .forgot-username {
        border: 0;
        padding: 0;
        width: 20.5rem;
        margin: 0 auto;
    }

    .full-form-error {
        margin: 0 0 1rem 0;
    }

    .forgot-password {
        .content-header {
            padding-top: 1rem !important;
        }
    }


    .forgot-password-form {
        margin-top: 2rem;

        .div-error {
            margin-bottom: 1rem;
        }

    }

}