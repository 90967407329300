.site-header {
  display:flex;
  align-items:center;
  position:relative;
  top:0px;
  left:0px;
  width:100%;
  z-index:200;
  height:64px;
  padding:0px 15px;
  //background:white;
  //box-shadow:0 2px 4px rgba(black, 0.1);
  
  background: rgba(white, .5);
  transition: background .5s ease-in-out;
  
  &:hover {
	  background: white;
  }

  @include media-breakpoint-up(md) {
    height:84px;
    padding:0px 30px;
  }

  &.fixed {
    position:fixed;
    top:-64px;

    ~ .site-header__offset {
      height:64px;
    }

    @include media-breakpoint-up(md) {
      top:-84px;

      ~ .site-header__offset {
        height:84px;
      }
    }
  }

  &.slide {
	  background: white;
    transition:transform 300ms ease;

    &.slide--active {
      transform:translate(0, 100%);
    }
  }

  &__logo {
    padding:0px 5px;

    img {
      width:165px;
      height:auto;
    }

    @include media-breakpoint-up(md) {
      width:230px;
      padding:0px 10px;
    }
  }

  &__menu {
    > .site-header__menu__item > a {
      cursor: pointer;
      display:table;
      position:relative;
      font-weight:$font-weight-bold;
      text-decoration:none;
      color:inherit;
      transition:color 300ms ease;

      @include hover-focus {
        color:$primary;
      }
    }

    @include media-breakpoint-up(xl) {
      margin:0 auto;

      > .site-header__menu__item {
        > a {
          display:inline-block;
          margin:0 17px;
          padding:30px 0px;

          &:after {
            content:'';
            position:absolute;
            left:0px;
            bottom:0px;
            width:100%;
            border-bottom:2px solid transparent;
          }

          &.active {
            &:after {
              border-bottom-color:$primary;
            }
          }
        }

        > button {
          display:none;
        }

        @include hover-focus {
          .site-header__sub-menu {
            opacity:1;
            transition-delay:0ms;
          }
        }

        &.visible .site-header__sub-menu {
          pointer-events:auto;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      position:absolute;
      top:100%;
      left:0px;
      z-index:-1;
      width:100%;
      height:calc(100vh - 64px);
      padding:0px 20px;
      background:white;
      overflow-y:auto;
      overflow-x:hidden;
      opacity:0;
      transform:translate(-100vw, 0);
      transition:opacity 600ms ease, transform 600ms ease;

      body.menu-open & {
        opacity:1;
        transform:translate(0, 0);
      }

      > .site-header__menu__item {
        display:flex;
        padding:26px 0px;

        + .site-header__menu__item {
          border-top:1px solid rgba(#D3D4D6, 0.5);
        }

        > a {
          font-size:fontsize(22);
          line-height:$headings-line-height;
        }

        > button {
          display:block;
          width:24px;
          height:24px;
          margin:0px 0px 0px auto;
          padding:4px;
          border:none;
          border-radius:0px;
          background:transparent;

          img {
            display:block;
          }
        }

        &.submenu-active {
          .site-header__sub-menu {
            transform:translate(-100%, 0px);
          }
        }
      }
    }
  }

  &__sub-menu {
    .site-header__menu__item {
      .two-column-features__tile {
        max-width:100%;
        padding:0px;
        font-size:fontsize(12);
        color:#767676;

        &__title {
          font-size:fontsize(22);
        }
      }
    }

    @include media-breakpoint-up(xl) {
      position:absolute;
      top:100%;
      left:0px;
      width:100%;
      padding:48px 0px 60px;
      border-top:1px solid #979797;
      background:white;
      pointer-events:none;
      opacity:0;
      transition:opacity 450ms ease;
      transition-delay:300ms;

      @include hover-focus {
        pointer-events:auto;
        opacity:1;
        transition-delay:0ms;
      }

      &__inner {
        @include make-row();
        margin-bottom:-43px;

        > button {
          display:none;
        }

        .site-header__menu__item {
          @include make-col-ready();
          @include make-col(4);
          padding-bottom:43px;

          .two-column-features__tile {
            img {
              width:36px;
              height:36px;
              margin-top:-4px;
              margin-right:16px;
            }

            &__title {
              margin-bottom:6px;
            }

            &__body {
              max-width:272px;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      position:absolute;
      top:0px;
      left:100%;
      z-index:1;
      width:100%;
      height:100%;
      background:white;
      overflow:auto;
      transition:transform 300ms ease;

      &__inner {
        > button {
          display:block;
          height:24px;
          margin:20px 0px 10px;
          padding:4px;
          border:none;
          border-radius:0px;
          background:transparent;

          img {
            display:inline-block;
            margin-right:5px;
          }
        }

        .site-header__menu__item {
          display:block;
          padding:26px 0px;

          + .site-header__menu__item {
            border-top:1px solid rgba(#D3D4D6, 0.5);
          }

          .two-column-features__tile {
            img {
              width:24px;
              height:24px;
              margin-right:24px;
            }

            &__title {
              margin-bottom:0px;
            }

            &__body {
              display:none;
            }
          }
        }
      }
    }
  }

  &__actions {
    &--mobile {
      margin-top:1rem;

      a {
        width:100%;
      }

      @include media-breakpoint-up(xl) {
        display:none;
      }
    }

    &--desktop {
      width:235px;
      text-align:right;

      @include media-breakpoint-down(lg) {
        display:none;
      }
    }
  }

  &__menu-toggle {
    display:block;
    width:32px;
    height:32px;
    margin:0px 0px 0px auto;
    padding:0px;
    border:none;
    border-radius:0px;
    //background: white;
    background: transparent;

    @include media-breakpoint-up(xl) {
      display:none;
    }
  }

  &__search {
    position:absolute;
    top:100%;
    left:0px;
    width:100%;
    border-top:1px solid #979797;
    background:white;
    box-shadow:0 2px 4px rgba(black, 0.1);
    pointer-events:none;
    opacity:0;
    transition:opacity 300ms ease;

    body.search-open & {
      opacity:1;
      pointer-events:auto;
    }

    &__inner {
      display:flex;
      position:relative;
      max-width:1046px;
      margin:0 auto;
    }

    [type="search"] {
      display:block;
      width:100%;
      height:68px;
      border:none;
      border-radius:0px;
      background:transparent;
      font-size:fontsize(20);

      &::placeholder {
        color:#404041;
      }
    }

    [type="submit"] {
      position:absolute;
      opacity:0;
    }

    &__backdrop {
      position:fixed;
      top:0px;
      left:0px;
      z-index:199;
      width:100%;
      height:100%;
      background:rgba(white, 0.8);
      pointer-events:none;
      opacity:0;
      transition:opacity 300ms ease;

      body.search-open & {
        opacity:1;
      }
    }
  }

  &__backdrop {
    @include media-breakpoint-up(lg) {
      position:fixed;
      top:0px;
      left:0px;
      z-index:199;
      width:100%;
      height:100%;
      background:rgba($dark, 0.85);
      pointer-events:none;
      opacity:0;
      transition:opacity 600ms ease;
      transition-delay:300ms;

      &.active {
        opacity:1;
        transition-delay:0ms;
      }
    }
  }
}





.header-logo img{
  max-height: 28px;
}
#submenu{
  height: 76px;
  background-color: #001928;
}
#submenu a.nav-link{
  color: #fff;
  padding: .4rem 1rem;
}
.sticky-submenu{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
