html{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.menu-open {
	overflow:hidden;
}

:hover,
:focus,
:active {
	outline: none !important;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	em {
		font-weight:$font-weight-light;
		font-style:inherit;
	}
}

h1, .h1 {
	@include media-breakpoint-down(sm) {
		font-size:fontsize(48);
	}
}
h2, .h2 {
	@include media-breakpoint-down(sm) {
		font-size:fontsize(36);
	}
}
h3, .h3 {
	@include media-breakpoint-down(sm) {
		font-size:fontsize(26);
	}
}
h4, .h4 {
	@include media-breakpoint-down(sm) {
		font-size:fontsize(20);
	}
}
h5, .h5 {
	@include media-breakpoint-down(sm) {
		font-size:fontsize(16);
	}
}
h6, .h6 {
	@include media-breakpoint-down(sm) {
		font-size:fontsize(12);
	}
}

strong,
b {
	font-weight:$font-weight-bold;
}

.clay-bg{
  background-color: #ECEEF0;
}
.light-grey-bg{
  background-color: $light;
}
.midnight-bg{
  background-color: $dark;
}
.white-text{
  color: #fff;
}

[data-toggle='class'] {
	&.classed .classed__hidden,
	&:not(.classed) .classed__visible {
		display:none;
	}
}