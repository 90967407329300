/**
 * HOME PAGE
 */
.c-home {
	
	//
	// HERO UNIT
	//
	&__hero {
		position: relative;
		
		&:before {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba($color-blue-dark, .25);
		}
		
		@include media-breakpoint-up(md) {
			margin-top: -100px;
		}
		@include media-breakpoint-up(sm) {
			padding-top: 300px;
			padding-bottom: 200px;
		}
		@include media-breakpoint-down(sm) {
			padding-top: 60px;
			padding-bottom: 60px;
		}
		
		&-description {
			text-align: center;
			color: white;
			
			& > :first-child {
				margin-top: 0;
			}
			& > :last-child {
				margin-bottom: 0;
			}
		}
		
		h1 {
			text-transform: uppercase;
			text-shadow: 6px 6px 6px rgba(black, .75);
			line-height: 1em;
			margin-bottom: .2em;
			
			@include media-breakpoint-up(md) {
				font-size: 3.75rem;
			}
		}
		
		p {
			max-width: 800px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 1em;
			font-size: 150%;
			text-shadow: 2px 2px 2px rgba(black, .75);
		}
		
		.btn {
			text-shadow: none;
		}
	}
	
	//
	// call to action
	//
	&__cta {
		padding-top: 20px;
		padding-bottom: 20px;
		
		&-flex {
			position: relative;
			
			@include media-breakpoint-up(md) {
				display: flex;
				align-content: space-between;
				align-items: center;
			}
			@include media-breakpoint-down(md) {
				display: block;
			}
		}
		
		&-description {
			text-align: center;
			font-size: 175%;
			color: $color-blue-dark;
			
			p {
				line-height: 1.2em;
				margin-bottom: .5em;
			}
			
			@include media-breakpoint-up(md) {
				flex: 1 1 auto;
				text-align: left;
				padding-right: 1em;
				
				p {
					margin-bottom: 0;
				}
			}
		}
		
		&-action {
			text-align: center;
			
			@include media-breakpoint-up(md) {
				flex: 1 1 auto;
				text-align: right;
			}
		}
		
		&--action-left {
			@include media-breakpoint-up(md) {
				.c-home__cta-description {
					order: 2;
					padding-right: 0;
					padding-left: 1em;
				}
				.c-home__cta-action {
					order: 1;
					text-align: left;
				}
			}
		}
	}
	
	//
	// VIDEO SECTION
	//
	&__video {
		padding-top: 20px;
		padding-bottom: 20px;
		background-color: #efefef;
		
		@include media-breakpoint-up(md) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}
	
	//
	// PRODUCTS SECTION
	//
	&__products {
		padding-top: 30px;
		padding-bottom: 30px;
		background-image:linear-gradient(
			45deg,
			$color-blue-dark 0%, 
			$color-blue-dark 30%, 
			$color-purple 70%,
			$color-blue-dark 90%,
			$color-blue-dark 100%
		);
		
		.col {
			padding-left: 2em;
			padding-right: 2em;
		}
		
		.slick-prev,
		.slick-next {
			position: absolute;
			display: block;
			width: 30px;
			height: 30px;
			top: 50%;
			text-indent: -99999em;
			overflow: hidden;
			padding: 0;
			margin: 0;
			border: none;
			background: transparent;
			line-height: normal;
			-webkit-font-smoothing: inherit;
			-moz-osx-font-smoothing: inherit;
			-webkit-appearance: none;
			
			&:before {
				content: "";
				position: absolute;
				display: block;
				width: 100%;
				height: 2px;
				top: 0;
				left: 0;
				right: 0;
				background-color: white;
			}
		}
		
		.slick-prev {
			left: 0;
			transform: translate(0, -50%) rotate(-45deg);
			
			@include media-breakpoint-up(md) {
				left: -2em;
			}
			
			&:after {
				content: "";
				position: absolute;
				display: block;
				height: 100%;
				width: 2px;
				top: 0;
				left: 0;
				bottom: 0;
				background-color: white;
			}
		}
		
		.slick-next {
			right: 0;
			transform: translate(0, -50%) rotate(45deg);
			
			@include media-breakpoint-up(md) {
				right: -2em;
			}
			
			&:after {
				content: "";
				position: absolute;
				display: block;
				height: 100%;
				width: 2px;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: white;
			}
		}
		
		a {
			text-decoration: none;
		}
		
		&-slide {
			color: white;
			text-align: center;
			color: white;
			
			display: block;
			
			@include media-breakpoint-up(md) {
				display: flex;
				align-items: center;
			}
			
			span {
				position: relative;
				
				&:before {
					content: "";
					position: absolute;
					bottom: -.2em;
					left: 0;
					width: 0%;
					height: 2px;
					background-color: white;
					transition: width 0.2s ease-in-out;
				}
			}
			
			&:hover span:before {
				width: 100%;
			}
		}
		
		&-slide-icon {
			display: block;
			
			@include media-breakpoint-up(md) {
				flex: 0 0 40px;
			}
			
			svg {
				max-width: auto;
				height: auto
			}
		}
		
		&-slide-label {
			display: block;
			text-align: center;
			padding: .5em;
			font-size: 125%;
			line-height: 1em;
			
			@include media-breakpoint-up(md) {
				flex: 1 1 auto;
				text-align: left;
			}
		}
	}
	
	//
	// SPLIT BLOCK
	//
	&__split {
		position: relative;
		
		@include media-breakpoint-up(xl) {
			display: flex;
			align-items: center;
			
			&.c-home__split--img-left {
				justify-content: flex-start;
			}
			&.c-home__split--img-right {
				justify-content: flex-end;
			}
		}
		
		@include media-breakpoint-between(sm, md) {
			max-width: map-get($container-max-widths, "sm");
			margin-left: auto;
			margin-right: auto;
			padding-left: $spacer;
			padding-right: $spacer;
		}
		@include media-breakpoint-between(md, lg) {
			max-width: map-get($container-max-widths, "md");
			margin-left: auto;
			margin-right: auto;
			padding-left: $spacer;
			padding-right: $spacer;
		}
		
		&-image {
			position: relative;
			display: block;
			text-align: center;
			
			img {
				display: block;
				width: 100%;
				height: auto;
			}
			
			@include media-breakpoint-up(xl) {
				flex: 0 0 50%;
				align-self: stretch;
				
				.c-home__split--img-right & {
					order: 2;
				}
			}
		}
		
		&-description {
			position: relative;
			display: block;
			padding: 2em;
			
			& > :first-child {
				margin-top: 0;
			}
			& > :last-child {
				margin-bottom: 0;
			}
			
			h2, p {
				margin-bottom: 1rem;
			}
			
			@include media-breakpoint-between(sm, lg) {
				padding-left: 0;
				padding-right: 0;
			}
			@include media-breakpoint-up(xl) {
				flex: 0 0 map-get($container-max-widths, "xl")/2;
				
				.c-home__split--img-right & {
					order: 1;
					padding-left: $spacer;
				}
				.c-home__split--img-left & {
					padding-right: $spacer;
				}
			}
			@include media-breakpoint-up(xxl) {
				flex: 0 0 map-get($container-max-widths, "xxl")/2;
			}
		}
	}
	
	//
	// INDUSTRIES
	//
	&__industries {
		padding-top: 30px;
		padding-bottom: 30px;
		background-image:linear-gradient(
			45deg,
			$color-orange 0%, 
			$color-orange 30%, 
			$color-yellow 85%,
			lighten($color-yellow, 40%) 100%
		);
		text-align: center;
		
		h2 {
			color: #fff;
		}
		
		a {
			color: #fff;
			text-decoration: none;
			margin-bottom: $spacer;
			
			span {
				position: relative;
				margin-left: .5em;
				font-size: 125%;
				line-height: 1em;
				
				&:before {
					content: "";
					position: absolute;
					bottom: -.2em;
					left: 0;
					width: 0%;
					height: 2px;
					background-color: white;
					transition: width 0.2s ease-in-out;
				}
			}
			
			&:hover span:before {
				width: 100%;
			}
		}
	}
	
	//
	// ENTERPRISE
	//
	&__enterprise {
		&-description {
			p {
				font-size: 150%;
			}
			@include media-breakpoint-down(md) {
				color: white;
				
				h2 {
					text-shadow: 4px 4px 4px rgba(black, .75);
				}
				p {
					text-shadow: 2px 2px 2px rgba(black, .75);
				}
			}
		}
		&-btn {
			position: relative;
			
			@include media-breakpoint-up(md) {
				.btn {
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}
		}
	}
}