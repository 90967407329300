.full-form-error {
    margin: 0px 1rem 1rem;
    max-width: 90%;
    border: 0px;
    background: transparent;
    color: rgb(222, 23, 40);
    font-weight: 300;
    padding: 0px 0px 0px 1.5rem;
    font-style: italic;

    i {
      position: absolute;
      font-size: 1rem;
      top: 0.1rem;
      left: 0;
      }
    }

  .div-error {
    display:block;
    width: 100%;
    color: rgb(222, 23, 40);
    font-weight: 300;
    font-style: italic;
    position: relative;
    
    .error {
      margin-left: 1.5rem;
    }

    i{
      margin-right: 0.5rem;
      color: #fff;
      width: 1rem;
      height: 1rem;
      line-height: 1rem;
      text-align: center;
      border-radius: 2rem;
      font-size: 0.6rem;
      position: absolute;
      left: 0;
      z-index: 1;

      &:after {
        content: '';
        width: 1rem;
        height: 1rem;
        border-radius: 50% 50% 0 50%;
        transform: rotate(45deg);
        background: #de1728;
        position: absolute;
        left: 0;
        z-index: -1;
      }

    }

  }
  
  
  .form-group {
    margin: 0;
    max-width: 25.25rem;
    }

  .page-form {
    .fieldsets-container {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
    }

    .fieldset-wrap {
      width: 100%;
    }
  }

  .input-group {
    .form-control {
      border-radius: 0;
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
      border-right: 0;
    }
  }