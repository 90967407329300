.inpage-menu {
	position:sticky;
	top:0px;
	left:0px;
	z-index:100;
	height:76px;
	margin:0px 0px -76px;
	padding:20px 0px;
	background:rgba($dark, 0.85);
	color:white;

	@include media-breakpoint-down(md) {
		height:56px;
		padding:17px 0px;
		margin:0px 0px -56px;

		&.inpage-menu-active {
			background:$dark;

			nav {
				transform:translate(0, 0);
			}
		}

		.container {
			max-width:100%;
		}
	}

	&__inner {
		display:flex;
		align-items:center;
	}

	h1, h2 {
		font-size:fontsize(16);
		margin: 0 25px;
		font-weight: 100;

		img {
			display:inline-block;
			margin:-3px 16px 0px 0px;
		}

		@include media-breakpoint-down(md) {
			padding:0px 25px;
			font-size:fontsize(16);
		}

		@include media-breakpoint-down(sm) {
			padding:0px 5px;
		}
	}

	nav {
		a {
			display:block;
			font-size:fontsize(18);
			color:inherit;

			&.button {
				@extend .btn;
				@extend .btn-light;
				@extend .btn-sm;
			}
		}

		@include media-breakpoint-up(lg) {
			margin:0px 0px 0px auto;

			a {
				display:inline-block;
				margin:0px 15px;
				font-size:fontsize(12);
			}
		}

		@include media-breakpoint-down(md) {
			position:absolute;
			top:100%;
			left:0px;
			z-index:-1;
			width:100%;
			height:calc(100vh - 56px);
			padding:0px ($grid-gutter-width*0.5 + 5px);
			background:$dark;
			transition:transform 300ms ease;
			transform:translate(0, -100vh);

			a:not(.button) {
				padding:16px 0px;

				+ a:not(.button) {
					border-top:1px solid rgba(#D3D4D6, 0.5);
				}
			}

			a.button {
				width:100%;
				margin:16px 0px;
				padding-top:$btn-padding-y-lg;
				padding-bottom:$btn-padding-y-lg;
				font-size:fontsize(14);
			}
		}

		@include media-breakpoint-down(sm) {
			height:calc(100vh - 56px);
		}
	}

	button {
		display:block;
		width:24px;
		height:24px;
		margin:0px 0px 0px auto;
		padding:4px;
		border:none;
		border-radius:0px;
		background:transparent;

		img {
			display:block;
		}

		@include media-breakpoint-up(lg) {
			display:none;
		}
	}
}

.product-overview {
	padding:64px 0px;

	&__copy {
		@include media-breakpoint-up(md) {
			order:1;
			padding-left:calc(((100vw - #{map-get($container-max-widths, "md")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(lg) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "lg")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(xl) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "xl")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(xxl) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "xxl")}) / 2) + #{$grid-gutter-width/2});
		}

		ul {
			padding:0px;
			list-style: none;

			li {
				padding:0px 0px 0px 36px;
				font-size:fontsize(16);
				line-height:lineheight(28, 20);
				font-weight:$font-weight-light;

				+ li {
					margin-top:12px;
				}

				&:before {
					content: '';
					display:block;
					float:left;
					width:12px;
					height:12px;
					border-radius:12px;
					background: $secondary;
					margin:6px 0px 0px -36px;
				}
			}

			@include media-breakpoint-up(lg) {
				li {
					font-size:fontsize(20);

					&:before {
						margin-top:10px;
					}
				}
			}
		}
	}

	&__image {
		padding-right:0px;
		text-align:right;

		img {
			display:inline-block;
			width:auto;
			height:auto;
			max-width:100%;
			margin:-22% 0px 0px 0px;
		}

		@include media-breakpoint-up(md) {
			order:2;
		}

		@include media-breakpoint-down(sm) {
			margin:0px 0px 33px;
		}
	}
}