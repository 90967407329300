/**
 * RESOURCES
 */
.c-resources {
	
	&__cards {
		.col-12 {
			margin-bottom: 2rem;
		}
	}
	
	&__card {
		height: 100%;
		
		.card-body {
			a:not(.btn) {
				color: $color-orange;
				
				&:hover {
					color: $color-orange;
				}
			}
		}
	}
	
	&__card-header {
		position: relative;
		display: block;
		width: 100%;
		min-height: 140px;
		overflow: hidden;
		
		background-image: linear-gradient(45deg, 
			#446FB6 0%, 
			#584DA0 62%,
			#49A9DF 100%
		);
		
		&:before {
			content: "";
			position: absolute;
			display: block;
			top: 10px;
			right: 15px;
			bottom: 10px;
			width: 100px;
			background: url('/assets/img/icons/logo-white.svg') top right no-repeat;
			background-size: contain;
		 }
		
		&:after {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: url('/assets/img/logomark.svg') left bottom no-repeat;
			background-size: contain;
			transform: scale(1.2);
			opacity: .5;
		}
	}
	
	&__card-label {
		position: absolute;
		display: block;
		top: 15px;
		left: 15px;
		max-width: 70%;
	}
	
	&__card-category {
		color: white;
		line-height: 1.2em;
		text-transform: uppercase;
		font-size: 120%;
		margin-bottom: 0 !important;
		
		small {
			font-size: inherit;
			text-transform: lowercase;
		}
	}
	&__card-title {
		color: white;
		line-height: 1.2em;
		text-transform: uppercase;
		font-size: 150%;
		font-weight: 700;
		margin-bottom: 0 !important;
		
		small {
			font-size: inherit;
			text-transform: lowercase;
		}
	}
	&__card-icon {
		position: absolute;
		right: 15px;
		bottom: 15px;
		width: auto;
		height: 50px;
	}
}