.two-column-features {
	position:relative;
	padding:130px 0px 40px;

	@include media-breakpoint-up(md) {
		padding:130px 0px 40px;

		.hero + & {
			padding-top:30px;
		}

		.container {
			max-width:928px + $grid-gutter-width;
		}
	}

	&__inner {
		@include make-row();

		@include media-breakpoint-up(lg) {
			margin:0px -36px;
		}
	}

	&__tile {
		@include make-col-ready();
		@include make-col(12);
		padding-bottom:31px;

		img {
			float:left;
			width:40px;
			height:40px;
			margin:0px 12px 0px 0px;
			object-fit:contain;
		}

		&__title {
			@include clearfix();
			margin:0px 0px 16px;
			color:$dark;

			a {
				text-decoration:none;
				color:inherit;
			}
		}

		&__link {
			display:inline-block;
			margin:10px 0px 0px;
			text-decoration:underline;
			color:$primary;
		}

		@include media-breakpoint-up(sm) {
			@include make-col(6);
			padding-top:31px;
		}

		@include media-breakpoint-up(lg) {
			padding:0px 36px 89px;

			img {
				margin-right:24px;
			}
		}

		@include media-breakpoint-down(xs) {
			& + & {
				&:before {
					content:'';
					display:block;
					margin:0px 0px 31px;
					border-top:1px solid rgba(#D3D4D6, 0.5);
				}
			}
		}
	}
}