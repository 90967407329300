.three-column-features {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    margin-top: 0.3rem;

    .product-option:not(.no-shadow) {
        -webkit-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
        -moz-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
        box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
    }

    .product-option {
        padding: 2rem 1.9rem;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: left;
        max-width: 32%;
        min-width: 32%;
        background: #fff;
        border: 1px solid #e6eaec;
        border-radius: .25rem;
        margin: 0.2rem 0.2rem;

        @media (max-width: 1021px) {
            padding: 2rem 1rem;
            max-width: 48.5%;
            min-width: 48.5%;
        }


        @media (max-width: 550px) {
            max-width: 100%;
            min-width: 100%;
            margin: 0.5rem 0;
        }
    
        .product-image {
            padding: 2rem;
            margin-bottom: 2rem;
            background: #fafafd;
            border-radius: 10rem;
            display: inline-block;
            min-width: 8rem;
            min-height: 8rem;
            max-width: 8rem;
            margin: 0 auto 2rem;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            -moz-justify-content: center;
            -ms-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            position: relative;
            -webkit-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
            -moz-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
            box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;

            img {
                max-width: 3rem;
            }

            .beta-badge {
                position: absolute;
                top: 1.5rem;
                right: 0;
                margin: auto;
                background: #91eae4;
                font-weight: 500;
                color: #191919;
                font-size: 0.7rem;
                border-radius: 0.15rem;
                padding: 0.12rem 0.18rem;
                line-height: 1;
            }
        }

        p:not(.inline-error) {
            min-height: 3.5rem;
            margin-left: auto;
            margin-right: auto;
        }
        
        .btn {
            min-width: 100%;
            i {
                margin-right: 0.4rem;
                font-size: 0.75rem;
            }

            +small {
                margin-top: 1.5rem;
                display: block;
            }
        }
        .icon-after {
            i {
                margin: 0 0 0 0.3rem;
                font-size: 0.7rem;
            }
        }

    }

    h3 {
        color: #0273FF;
        font-weight: 500;
        margin-bottom: .5rem;
        
        @include media-breakpoint-up(md) {
            font-size: 1.2rem;
        }

    }

    p {
        min-height: 3.5rem;
        max-width: 28rem;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1.5rem;
        margin-bottom: 0rem;
    }
}
