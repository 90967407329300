.multiselect-container {
    border: 1px solid rgb(229, 229, 229);
    color: rgb(25, 25, 25);
    max-height: 20.4rem;
    padding: 0px 0px 0.5rem;
    font-size: 0.85rem;
    width: 100%;
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.176) 0px 0px 12px;
    background: rgb(255, 255, 255);
    max-width: 28rem;
    overflow-y: visible;

    .input-group {
      margin: 0;
    }

}

span.multiselect-native-select {
  .dropdown-menu {
    .filter {
      padding: 0.5rem;
      border-bottom: 1px solid #e6eaec;
      background: #f5f6fc;
      position: absolute;
      z-index: 1;
      width: 100%;

      input {
        border-radius: .25rem !important;
        width: 100%;
        padding-left: 1.8rem;
        padding-right: 1.8rem;
      }

      .input-group-btn {
        display: none;
        top: 0;
        min-width: 1.1rem;
        right: 0.5rem;
      }

      .input-group-addon {
        border: none;
        background: transparent;
        position: absolute;
        z-index: 4;
        font-size: 0.8rem;
        top: 0.7rem;
        left: 0.6rem;
        color: #6f6f6f;

        &+.form-control{
          border-right: 1px solid #e5e5e5;
        }
      }

      .multiselect-search {
        text-align: left;
        font-style: italic;
      }

    }
    li {
      a {
        width: 100%;
        display: block;
        label {
          padding: 0.8rem 0 0.8rem 1.7rem;
          width: 100%;
          position: relative;
          font-weight: 300;
          margin: 0;
          &.radio{
            padding-left: 1rem;
            input {
              visibility: hidden;
            }
          }
          input {
            position: absolute;
            left: 0.5rem;
            top: 1.05rem;
          }
        }

        &:hover {
          cursor: default;
          background: #ddf4ff;
        }

      }

      &:not(.multiselect-group) {
        a:hover , label:hover {
          cursor: pointer;
        }

      }

      &.multiselect-group {
        label {
          padding: 0.4rem 0.5rem 0.2rem !important;
        }
      }

    }
    .options-wrapper {
      max-height: 16.4rem;
      margin-top: 3.7rem;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      li:first-child {
        .radio {
          display: none;
        }
      }

    }
  }

}