.tcs {
	position:relative;
	padding:40px 0px;

	@include media-breakpoint-up(md) {
		padding:88px 0px;
	}

	&__nav {
		padding:7px 20px;
		border:1px solid #BEBEBE;
		border-radius:16px;

		a {
			display:block;
			padding:17.5px 0px;
			color:inherit;

			+ a {
				border-top:1px solid #BEBEBE;
			}
		}

		@include media-breakpoint-down(sm) {
			margin-bottom:40px;
		}

		@include media-breakpoint-up(md) {
			position:sticky;
			top:84px;
			left:0px;
		}
	}

	&__entry {
		& + & {
			margin-top:40px;
			padding-top:40px;
			border-top:1px solid #BEBEBE;

			@include media-breakpoint-up(md) {
				margin-top:80px;
				padding-top:80px;
			}
		}

		&__title {
			margin:0px 0px 40px;

			@include media-breakpoint-up(md) {
				margin-bottom:80px;
			}
		}
	}
}