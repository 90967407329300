@import "simple/simple-fonts";

.layout-simple {

  @import "simple/simple-typography";
  @import "simple/simple-modals";
  @import "simple/simple-buttons";
  @import "simple/simple-forms";
  @import "simple/simple-miltiselect";

  background: #f5f6fc;
  background: -moz-linear-gradient(-45deg, #f5f6fc 0%, #f5f6fc 49%, #e9e7f1 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #f5f6fc), color-stop(49%, #f5f6fc), color-stop(100%, #e9e7f1));
  background: -webkit-linear-gradient(-45deg, #f5f6fc 0%, #f5f6fc 49%, #e9e7f1 100%);
  background: -o-linear-gradient(-45deg, #f5f6fc 0%, #f5f6fc 49%, #e9e7f1 100%);
  background: -ms-linear-gradient(-45deg, #f5f6fc 0%, #f5f6fc 49%, #e9e7f1 100%);
  background: linear-gradient(135deg, #f5f6fc 0%, #f5f6fc 49%, #e9e7f1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafd', endColorstr='#e9e7f1', GradientType=1 );
  font-family: "Monorale",Arial,sans-serif;
  font-size: .85rem;
  color: #191919;
  font-weight: 300;
  line-height: 1.55;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  -ms-font-feature-settings: "lnum";
  -o-font-feature-settings: "lnum";
  font-feature-settings: "lnum";


  .layout {
    position: relative;
    padding-bottom: 38px;
    min-height: 100vh;

    @include media-breakpoint-up(md) {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      }
    }

  .simple-header {
      padding: 1.75rem 1rem;
      border-bottom: 1px solid #e6eaec;
      background: #001e35;
      background: -moz-linear-gradient(-45deg, #001e35 0%, #001e35 49%, #00070c 100%);
      background: -webkit-gradient(left top, right bottom, color-stop(0%, #001e35), color-stop(49%, #001e35), color-stop(100%, #00070c));
      background: -webkit-linear-gradient(-45deg, #001e35 0%, #001e35 49%, #00070c 100%);
      background: -o-linear-gradient(-45deg, #001e35 0%, #001e35 49%, #00070c 100%);
      background: -ms-linear-gradient(-45deg, #001e35 0%, #001e35 49%, #00070c 100%);
      background: linear-gradient(135deg, #001e35 0%, #001e35 49%, #00070c 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$site-nav-bg', endColorstr='#e9e7f1', GradientType=1 );
      -webkit-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
      -moz-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
      box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;

      @include media-breakpoint-up(md) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }

      .logo {
        a {
          display: inline-block;
        }
        img {
          max-width: 10rem;
          @include media-breakpoint-up(md) {
            max-width: 11rem;
          }
        }

      }
  }

  .smaller-content-wrap {
    @include media-breakpoint-up(md) {
      max-width: 990px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      min-width: 100%;
    }
    @include media-breakpoint-up(xl) {
      min-width: 990px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 1rem;
    }

  }

  .text-link {
      white-space: nowrap;
      font-weight: 400;
      font-size: .85rem;
      color: #191919;
      text-decoration: underline;

      &>i{
        margin-right: 0.5rem;
        font-size: .7rem;
        margin-left: 0.1rem;
      }

    }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }


  .alt-logins {
      border-top: 1px solid #e6eaec;
      padding-top: 2rem;
      margin-top: 2rem;
      position: relative;

      small {
        position: absolute;
        color: #6f6f6f;
        background: #fff;
        padding: 0.2rem 0.5rem;
        top: -0.8rem;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        font-style: italic;
        width: 1.65rem;
      }
      .btn {
        display: block;
      }

    }

    .text-muted {
      color: #6f6f6f !important;
      font-size: .7522123894rem;
      font-style: italic;
      font-weight: 300;
  }

   .loading-mask-wrap {
    display: none;
    position: absolute;
    width: 100%;
    min-height: 100%;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    background: #fdfdfd;
    top: 0;
}

  .flow-btns {
    .primary-actions {

      & > * {
        @include media-breakpoint-up(md) {
          float: right;
          vertical-align: middle;
        }
      }

      & > .text-link {
        margin-top: 0;
        margin-right: 1rem;
        display: inline-block;
        line-height: 2.5rem;
      }
    }
  }

  footer {
      max-width: 990px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
  
        .footer-wrap {
          padding: 1rem 1rem 0;
          padding-bottom: 2.7rem;
          font-size: 0.75rem;
    
          li {
            margin-top: 0.3rem;
          }
    
          @include media-breakpoint-up(md) { 
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between;
            -webkit-align-items: flex-end;
            -moz-align-items: flex-end;
            -ms-align-items: flex-end;
            align-items: flex-end;
            }
          }
      }
  
     .back-top {
      position: fixed;
      bottom: 0;
      right: 10.5rem;
      transition: opacity 0.4s ease-out;
      opacity: 0;
      .btn {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
      }
      &.show {
          opacity: 1;
      }
    }


  }