@mixin promotextstyle {
    margin-bottom: 0.5rem;
    color: #fff;
    opacity: 0.9;
}

.promo-banner {
    background: #001835;
    border-radius: .25rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
    -moz-box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
    box-shadow: 5px 5px 12px rgba(0,0,0,0.06) !important;
    max-height: 14rem;
    overflow: hidden;
    padding-right: 1rem;

    @include media-breakpoint-down(sm) { 
        display: none;
    }
    
    .promo-banner-images {
        position: relative;
        max-height: 14rem;
        overflow: hidden;
        margin-right: 2.5rem;
        min-width: 24rem;

        .preview-image {
            max-width: 25rem;
            left: 4rem;
            top: 1rem;
            position: relative;
            opacity: 0.9;
            z-index: 3;
        }
        .decoration {
            position: absolute;
            max-height: 13.3rem;
            top: 0.4rem;
            left: 0.5rem;
            z-index: 2;
            opacity: 0.9;
        }

    }

    .promo-banner-text {
        h2 {
            @include promotextstyle;
            font-weight: 500;

            @include media-breakpoint-up(md) { 
                font-size: 1.4rem;
            }
            span {
                color: #0293FF;
            }
        }

        h5 {
            @include promotextstyle;
            font-weight: 400;
            span {
                color: #0293FF;
            }
        }

        p {
            @include promotextstyle;
            max-width: 28rem;
            margin-bottom: .9rem;

            a {
                color: #fff;
            }
        }

    }

    a {
        white-space: nowrap;
        font-weight: 400;
        font-size: .85rem;
        color: #191919;
        text-decoration: underline;
    }

}