.scrolling-tiles {
	position:relative;
	padding:80px 0px 70px;

	.copy-links + & {
		padding-top:0px;
	}

	@include media-breakpoint-up(md) {
		padding:75px 0px 80px;
	}

	&__copy {
	}

	&__buttons {
		&--desktop {
			text-align:right;

			@include media-breakpoint-down(sm) {
				display:none;
			}
		}

		&--mobile {
			margin:20px 0px 0px;
			text-align:center;

			@include media-breakpoint-up(md) {
				display:none;
			}
		}
	}

	&__tiles {
		display:flex;
		margin:28px 0px 0px;
		padding-left:$grid-gutter-width/2;
		overflow:auto;

		@include media-breakpoint-up(sm) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "sm")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(md) {
			margin:48px 0px 0px;
			padding-left:calc(((100vw - #{map-get($container-max-widths, "md")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(lg) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "lg")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(xl) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "xl")}) / 2) + #{$grid-gutter-width/2});
		}

		@include media-breakpoint-up(xxl) {
			padding-left:calc(((100vw - #{map-get($container-max-widths, "xxl")}) / 2) + #{$grid-gutter-width/2});
		}
	}

	&__tile {
		display:flex;
		flex-direction:column;
		position:relative;
		max-width:280px;
		min-width:280px;
		min-height:265px;
		margin:0px 12px 0px 0px;
		padding:30px;
		border-radius:16px;
		background:#F4F5F7;
		overflow:hidden;

		&__lead {
			font-size:fontsize(12);
			color:#767676;
		}

		&__title {
			margin-top:auto;
			color:$primary;
		}

		&__body {
			margin:20px 0px 0px;
			min-height:$line-height-base*2em;
		}

		@include media-breakpoint-up(md) {
			max-width:347px;
			min-width:347px;
			min-height:296px;

			&:before {
				content:'';
				position:absolute;
				right:-230%;
				bottom:-190%;
				width:300%;
				height:300%;
				background:radial-gradient(rgba(#5040D4, 0.83) 0%, rgba(#5040D4, 0.42) 20%, rgba(#5040D4, 0) 70%);
				opacity:0;
				transition:opacity 600ms ease;
			}

			@include hover-focus {
				&:before {
					opacity:1;
				}
			}
		}
	}
}