.logo-tabs {
	position:relative;
	padding:48px 0px 64px;

	.blog-posts + & {
		padding-top:0px;
	}

	@include media-breakpoint-up(md) {
		padding:80px 0px;
	}

	&__title {
		max-width:415px;
		margin:0px 0px 42px;
	}

	&__nav {
		margin:0px 0px 16px;

		a {
			display:inline-block;
			padding:4.5px 15px;
			border-radius:4px;
			font-size:fontsize(16);
			text-decoration:none;
			color:#767676;

			&.active {
				background:#F0ECEE;
				color:$dark;
			}
		}

		select {
			height:40px;
			padding:0px 12px;
			border:none;
			border-radius:4px;
			background-color:#F0ECEE;
			background-image:url('../img/icons/dropdown-arrow-alt.svg');
			background-position:calc(100% - 12px) center;
			background-size:13px;
		}

		@include media-breakpoint-up(md) {
			margin-bottom:22px;

			select {
				display:none;
			}
		}

		@include media-breakpoint-down(sm) {
			a {
				display:none;
			}
		}
	}

	&__tabs {
		.row {
			flex-wrap:nowrap;
			margin:0px -6px -12px;
			overflow:auto;

			.col {
				@include make-col(1, 7);
				min-width:144px + 12px;
				padding:0px 6px 12px;
			}
		}
	}

	&__logo {
		position:relative;
		padding:0px 0px 100%;
		border:1px solid #BEBEBE;
		border-radius:8px;

		img {
			position:absolute;
			top:10px;
			left:10px;
			width:calc(100% - 20px);
			height:calc(100% - 20px);
			object-fit:contain;
		}
	}
}